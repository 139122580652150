import { ReactNode } from 'react';
import { BaseCard, Stack, Title, Text } from '@tiendanube/components';

interface CardWithTitleProps {
  title?: string;
  subtitle?: string;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  tag?: ReactNode;
  children: ReactNode;
}

function CardWithTitle({
  title,
  subtitle,
  type = 'h3',
  tag,
  children,
}: CardWithTitleProps): JSX.Element {
  return (
    <BaseCard>
      {!!title && (
        <BaseCard.Header>
          <Stack column align="stretch" spacing="tight">
            <Stack>
              <Title type={type}>{title}</Title> {tag}
            </Stack>
            {!!subtitle && <Text> {subtitle} </Text>}
          </Stack>
        </BaseCard.Header>
      )}
      <BaseCard.Body padding="base">{children}</BaseCard.Body>
    </BaseCard>
  );
}

export default CardWithTitle;

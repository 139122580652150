import { Box, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { TablePricesDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import { DataList, Label } from '@tiendanube/components';

interface CustomerPriceTablesItemProps {
  priceTable: TablePricesDto;
}

export default function CustomerPriceTablesItem({
  priceTable,
}: Readonly<CustomerPriceTablesItemProps>) {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const tPath = 'customerDetails.customerPriceTables';

  return (
    <DataList.Row id={priceTable.id}>
      <DataList.Cell width={100} rowTitle>
        <Text fontWeight="bold">{priceTable.name}</Text>
      </DataList.Cell>
      <DataList.Cell width={100}>
        <Text>
          {priceTable.defaultValue}
          {t(`${tPath}.reductionPriceText`)}
        </Text>
      </DataList.Cell>
      <DataList.Cell width={100}>
        <Box display="flex" gap="2" marginTop="0-5">
          <Label
            id="ruleTypeLabel"
            label={t(`${tPath}.allProductsLabel`)}
            appearance="default"
          />
          <Label
            id="activeLabel"
            label={t(
              `${tPath}.${priceTable.active ? 'activeLabel' : 'inactiveLabel'}`,
            )}
            appearance="success"
          />
        </Box>
      </DataList.Cell>
    </DataList.Row>
  );
}

/* eslint-disable no-useless-escape */
import { TFunction } from 'i18next';

export function replacePreviewVariables(
  templateHtml: string,
  t: TFunction<'translation', undefined, 'translation'>,
  storeName: string,
) {
  return templateHtml
    .replace(/\{\{[ store_name ]*?\}\}/g, storeName)
    .replace(/\{\{[ contact_name ]*?\}\}/g, t('preview.customer.name'))
    .replace(/\{\{[ url ]*?\}\}/g, '#')
    .replace(/\{\{[ customer.name ]*?\}\}/g, t('preview.customer.name'))
    .replace(/\{\{[ order.id ]*?\}\}/g, t('preview.order.number'))
    .replace(/\{\{[ product.quantity ]*?\}\}/g, t('preview.product.quantity'))
    .replace(
      /\{\{[product.name ~ product.variationName]*?\}\}/g,
      t('preview.product.title'),
    )
    .replace(/\{\{[ product.priceFormat ]*?\}\}/g, t('preview.product.price'))
    .replace(/\{\{[ order.totalFormat ]*?\}\}/g, t('preview.order.total'))
    .replace(
      /\{\% if order.show_shipping_price(.*)[endif] \%\}/g,
      t('preview.shipping.price'),
    )
    .replace(/\{\% if order.shipping_extra.store_branch(.*)[endif] \%\}/g, '')
    .replace(
      /\{\{[ order.shipping_address ]*?\}\}/g,
      t('preview.shipping.address'),
    )
    .replace(/\{\{[ order.shipping_floor ]*?\}\}/g, t('preview.shipping.floor'))
    .replace(
      /\{\{[ order.shipping_locality ]*?\}\}/g,
      t('preview.shipping.locality'),
    )
    .replace(/\{\{[ order.shipping_city ]*?\}\}/g, t('preview.shipping.city'))
    .replace(
      /\{\{[ order.shipping_zipcode ]*?\}\}/g,
      t('preview.shipping.zipcode'),
    )
    .replace(
      /\{\{[ order.shipping_country ]*?\}\}/g,
      t('preview.shipping.country'),
    )
    .replace(
      /\{\{[ order.shipping_province ]*?\}\}/g,
      t('preview.shipping.province'),
    )
    .replace(/\{\{[ cart.contact_name ]*?\}\}/g, t('preview.customer.name'))
    .replace(/\{\{[ cart.abandoned_checkout_url ]*?\}\}/g, '#')
    .replace(/\{\{[ order.shipping_name ]*?\}\}/g, t('preview.shipping.name'))
    .replace(
      /\{\{[ order.shippingMethod ]*?\}\}/g,
      t('preview.shipping.method'),
    )
    .replace(/Costo de entrega:|Custo de entrega:|Delivery cost:/g, '')
    .replace(/\{\% for promotion[\s\S]*(endfor) \%\}/g, '')
    .replace(/\{\% if order.discountCoupon[\s\S]*?[endif] \%\}/g, '')
    .replace(/\{\% if order.discountGateway[\s\S]*?[endif] \%\}/g, '')
    .replace(/\{\% if order.tax[\s\S]*?[endif] \%\}/g, '')
    .replace(/\{\{[ order.shipping_option ]*?\}\}/g, '')
    .replace(/\{\% elseif order\.shipping\_p[\s\S]*[endif] \%\}/g, '')
    .replace(
      /\{\% if reason(.*)Cancelamos tu(.*)[endif] \%\}/g,
      t('preview.cancel.reason'),
    )
    .replace(
      /\{\% if reason(.*)Cancelamos seu(.*)[endif] \%\}/g,
      t('preview.cancel.reason'),
    )
    .replace(
      /\{\% if reason(.*)We cancel your order(.*)[endif] \%\}/g,
      t('preview.cancel.reason'),
    )
    .replace(
      /\{\% if payment_status(.*)pago(.*)[endif] \%\}/g,
      t('preview.cancel.status'),
    )
    .replace(
      /\{\% if payment_status(.*)pagamento(.*)[endif] \%\}/g,
      t('preview.cancel.status'),
    )
    .replace(
      /\{\% if payment_status(.*)payment(.*)[endif] \%\}/g,
      t('preview.cancel.status'),
    )
    .replace(/\{\%[\s\S]*?%\}/g, '')
    .replace(/\{\#[\s\S]*?#\}/g, '');
}

import { CheckboxProps } from '@nimbus-ds/components';

export const SHOW_PRODUCTS_INFO_LABEL = 'showProductsInfo';

const LABEL_SETTINGS_OPTIONS: CheckboxProps[] = [
  {
    label: 'showProductsInfo',
    name: 'showProductsInfo',
  },
  {
    label: 'showMerchantNote',
    name: 'showMerchantNote',
  },
  {
    label: 'showOrdersNote',
    name: 'showOrdersNote',
  },
  {
    label: 'showLabelRecipientDocument',
    name: 'showLabelRecipientDocument',
  },
  {
    label: 'showSenderInfo',
    name: 'showSenderInfo',
  },
];

export const LABEL_SETTING_RECEIPT_STUP: CheckboxProps = {
  label: 'showReceiptStub',
  name: 'showReceiptStub',
};

export const LABEL_SETTING_SUB_OPTIONS: CheckboxProps[] = [
  {
    label: 'showTotalPrices',
    name: 'showTotalPrices',
  },
  {
    label: 'showUnitPrices',
    name: 'showUnitPrices',
  },
  {
    label: 'showSku',
    name: 'showSku',
  },
];

export default LABEL_SETTINGS_OPTIONS;

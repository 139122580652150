import { useEffect } from 'react';
import { ConceptCode } from '@tiendanube/common';
import { useNavegate } from 'App/hooks';
import { HeaderTop, IonPageStratus } from 'commons/components';
import { ConceptDetailContent } from './components';
import {
  trackingBillingCheckoutViewDetails,
  VIEW_DETAILS_ORIGIN,
} from '../../tracking';

function ConceptDetailPage() {
  const { getQueryParam, goBack } = useNavegate();
  const backNavigation = {
    onClick: goBack,
  };

  const concept = getQueryParam('concept') as ConceptCode;
  const externalReference = getQueryParam('externalReference') || undefined;
  const chargeId = getQueryParam('chargeId') || undefined;
  const origin = (getQueryParam('origin') as VIEW_DETAILS_ORIGIN) || undefined;

  useEffect(() => {
    trackingBillingCheckoutViewDetails(origin);
  }, [origin]);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      width="small"
    >
      <ConceptDetailContent
        concept={concept}
        externalReference={externalReference}
        chargeId={chargeId}
      />
    </IonPageStratus>
  );
}

export default ConceptDetailPage;

import { Accordion, Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Input } from '@tiendanube/components';
import useScrollToError from 'App/components/AppLayout/useScrollToError';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import { trackingProductDetailVideoBlur } from 'domains/Catalog/Products/tracking';

import './Video.scss';

interface VideoProps {
  videoUrl?: string;
  errors: Partial<Record<string, string>>;
  onChange: ({ name, value }: { name: string; value: string }) => void;
  isEdit?: boolean;
}

function Video({
  videoUrl,
  errors,
  onChange,
  isEdit,
}: Readonly<VideoProps>): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const hasError = !!errors[`videoUrl`];
  const errorHelpText = errors[`videoUrl`] || '';
  const sender = useTrackFullCatalog();
  useScrollToError(errors);

  const handleBlur = ({ value }: { name: string; value: string }) => {
    sender(() => {
      trackingProductDetailVideoBlur(Boolean(value), isEdit);
    });
  };

  return (
    <div className="stratus--external-video">
      <Accordion>
        <Accordion.Item index="0">
          <Accordion.Header
            borderBottom="base"
            subtitle={t('products.detail.videoLabel')}
            title={t('products.detail.videoExternal')}
          />
          <Accordion.Body borderTop="base" borderBottom="base">
            <Box marginTop="4" width="100%">
              <Input
                type="text"
                label=""
                placeholder="https://"
                onChange={onChange}
                onBlur={handleBlur}
                name="videoUrl"
                value={videoUrl || ''}
                appearance={hasError ? 'validation_error' : undefined}
                helpText={hasError ? t(errorHelpText) : undefined}
              />
            </Box>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default Video;

import { useTranslation } from 'react-i18next';
import { i18nResponseDto } from '@tiendanube/common';
import { Domain } from '@tiendanube/common/src/enums';
import {
  Stack,
  BaseCard,
  Title,
  Text,
  Input,
  Link,
} from '@tiendanube/components';
import { ExternalLinkIcon } from '@tiendanube/icons';
import { LanguageNameValueInterface } from 'App/i18n';
import { useGetLanguage } from 'domains/Auth/hooks';
import { SuggestedGoogleShoppingCategories } from './components';
import Skeleton from './Skeleton';

const MAX_SEO_CATEGORY_INPUT_LENGTH = 300;

interface GoogleShoppingCategoriesProps {
  categoryId?: string;
  name?: i18nResponseDto;
  description?: i18nResponseDto;
  googleShoppingCategory: string;
  handleChange: (data: LanguageNameValueInterface) => void;
  errors?: Partial<Record<string, string>>;
}

function GoogleShoppingCategories({
  categoryId,
  name,
  description,
  googleShoppingCategory,
  handleChange,
  errors,
}: Readonly<GoogleShoppingCategoriesProps>): JSX.Element {
  const { t } = useTranslation(Domain.CATALOG);
  const title = t('categories.detail.googleShopping.title');
  const subtitle = t('categories.detail.googleShopping.subtitle');

  const errorGoogleCategory = errors?.googleShoppingCategory;

  //NOTE: We use the default language for the moment, we need to change google shopping categories to support multiple languages
  const lang = useGetLanguage();

  const handleOnUseGoogleShoppingCategory = (
    googleShoppingCategorySelected: string,
  ) => {
    handleChange({
      name: 'googleShoppingCategory',
      value: googleShoppingCategorySelected,
    });
  };

  const hasName = name && name[lang] !== undefined;
  const nameForSuggestedGoogleShoppingCategory = hasName
    ? name[lang] ?? ''
    : '';
  return (
    <div className="stratus--seo-categories">
      <BaseCard>
        <BaseCard.Header>
          <Stack column spacing="tight" align="stretch">
            <Title type="h3">{title}</Title>
            <Text>{subtitle}</Text>
          </Stack>
        </BaseCard.Header>
        <BaseCard.Body>
          <Stack column spacing="tight" align="stretch">
            <Input
              label={t('categories.detail.googleShopping.label')}
              value={googleShoppingCategory}
              name="googleShoppingCategory"
              onChange={handleChange}
              placeholder={t('categories.detail.googleShopping.placeholder')}
              maxLength={MAX_SEO_CATEGORY_INPUT_LENGTH}
              helpText={errorGoogleCategory ? t(errorGoogleCategory) : ''}
              appearance={errorGoogleCategory ? 'validation_error' : undefined}
            />
            {!!categoryId && !!description && hasName && (
              <SuggestedGoogleShoppingCategories
                categoryId={categoryId}
                name={nameForSuggestedGoogleShoppingCategory}
                description={description[lang]}
                onUseGoogleShoppingCategory={handleOnUseGoogleShoppingCategory}
              />
            )}
            <Text size="caption" textAlign="left">
              {t('categories.detail.googleShopping.help')}{' '}
              <Link
                href={t('categories.detail.googleShopping.link')}
                icon={ExternalLinkIcon}
                underline
                iconPosition="end"
                size="small"
                iconSize="small"
              >
                Google
              </Link>
            </Text>
          </Stack>
        </BaseCard.Body>
      </BaseCard>
    </div>
  );
}

GoogleShoppingCategories.Skeleton = Skeleton;

export default GoogleShoppingCategories;

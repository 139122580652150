import { useCallback } from 'react';
import {
  Alert,
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  Tag,
  Text,
} from '@nimbus-ds/components';
import {
  ErrorState,
  Input,
  InterfaceNameValue,
  Select,
} from '@tiendanube/components';
import { LockIcon } from '@tiendanube/icons';
import { useDesktopMenuContext } from 'App/components/Menu/DesktopMenuProvider';
import useTopBar from 'App/components/Topbar/useTopBar';
import { useToastStatus } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import {
  useAfterPaymentRedirection,
  useCreditCardCheckout,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';
import { expirationDateRegex } from 'domains/Billing/Checkout/hooks/utils';
import { trackingBillingPayCreditCardClick } from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import CreditCardDataCardSkeleton from './Skeleton';

interface CreditCardDataCardProps {
  readonly includeTransactionFee: boolean;
}

function CreditCardDataCard({
  includeTransactionFee,
}: CreditCardDataCardProps) {
  const t = useTranslationBilling(
    'checkout.payWithCreditCard.creditCardDataCard',
  );
  const { onCompletedPayment } = useAfterPaymentRedirection();
  const { isShowMenu, showMenu } = useTopBar();
  const { isShowMenu: isShowAsideMenu, showMenu: showAsideMenu } =
    useDesktopMenuContext();
  const country = useGetCountry();

  const { recurrentPaymentCompatible, mainConcept } = usePayorderToPay();

  const {
    errors,
    formValues,
    handleOnSubmit: executeForm,
    setFieldValue,
    isLoading,
    setupStatusObject: { isLoading: setupIsLoading, isError: setupIsError },
    cleanStatus,
    status,
  } = useCreditCardCheckout(includeTransactionFee);

  const handleOnSubmit = useCallback(() => {
    trackingBillingPayCreditCardClick(mainConcept);
    executeForm();
  }, [executeForm, mainConcept]);

  const onSuccess = useCallback(() => {
    onCompletedPayment();
    if (!isShowMenu || !isShowAsideMenu) {
      showMenu();
      showAsideMenu();
    }
  }, [
    onCompletedPayment,
    isShowMenu,
    isShowAsideMenu,
    showMenu,
    showAsideMenu,
  ]);

  const onError = useCallback(() => {
    cleanStatus();
  }, [cleanStatus]);

  useToastStatus({
    status,
    error: t('error'),
    success: t('success'),
    onSuccess,
    onError,
  });

  const getInputAttributes = (field: string) => {
    const error = errors[field];
    const helpText = error && error !== '' ? t(error) : '';
    const appearance =
      helpText !== '' ? ('validation_error' as const) : undefined;

    return {
      label: t(`form.${field}.label`),
      name: field,
      onChange: ({ value }) => setFieldValue(field, value),
      value: formValues[field],
      helpText,
      appearance,
    };
  };

  const handleExpirationDate = ({ value }: InterfaceNameValue) => {
    if (value.length > 2 && value[2] !== '/')
      value = value.slice(0, 2) + '/' + value.slice(2);

    if (!expirationDateRegex.test(value)) return;

    setFieldValue('expirationDate', value);
    if (value.length === 5) {
      setFieldValue('expirationMonth', value.slice(0, 2));
      setFieldValue('expirationYear', value.slice(3));
    }
  };

  if (setupIsError) return <ErrorState title={t('setupError')} />;

  /**
   * The loaded form is always present but hidden when mercado pago is loading.
   * This is because the setup for mercado pago needs the form to be present at all
   * times to properly mount.
   *  */
  return (
    <>
      {setupIsLoading && <CreditCardDataCardSkeleton />}
      <form id="form-checkout" hidden={setupIsLoading}>
        <Box display="flex" flexDirection="column" gap="2">
          <Card>
            <Card.Header title={t('title')} />
            <Tag appearance="neutral">
              <Icon source={<LockIcon size="small" />} /> {t('safetyTag')}
            </Tag>
            <Card.Body>
              <Box display="flex" flexDirection="column" gap="4">
                <Input {...getInputAttributes('cardNumber')} />
                <Input {...getInputAttributes('cardholderName')} />
                <Box display="flex" flexDirection="row" gap="4">
                  <Box display="flex" flexDirection="column" flexGrow="1">
                    <Input
                      {...getInputAttributes('expirationDate')}
                      onChange={handleExpirationDate}
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" flexGrow="1">
                    <Input {...getInputAttributes('securityCode')} />
                  </Box>
                </Box>
                {country !== 'MX' && (
                  <Box display="flex" flexDirection="row" gap="4">
                    <Box display="flex" flexDirection="column" flex="1 1 35%">
                      <Select
                        {...getInputAttributes('identificationType')}
                        options={[]}
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" flex="1 1 65%">
                      <Input {...getInputAttributes('identificationNumber')} />
                    </Box>
                  </Box>
                )}
                <Box display="flex" flexDirection="column" gap="2">
                  <Input {...getInputAttributes('cardholderEmail')} />
                  <Text fontSize="caption">
                    {t('form.cardholderEmail.suggestion')}
                  </Text>
                </Box>
                {recurrentPaymentCompatible && (
                  <Alert>{t('recurrencyAlert')}</Alert>
                )}

                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap="4"
                >
                  <Text>{t('processedBy')}</Text>
                </Box>
              </Box>
            </Card.Body>
          </Card>

          <Box display="flex" flexDirection="row" justifyContent="flex-end">
            <Button
              type="submit"
              appearance="primary"
              onClick={handleOnSubmit}
              disabled={isLoading}
            >
              {recurrentPaymentCompatible
                ? t('payButtonWithRp')
                : t('payButton')}
              {isLoading && <Spinner size="small" />}
            </Button>
          </Box>
        </Box>

        <input
          hidden
          id="input_expirationMonth"
          name="expirationMonth"
          value={formValues.expirationMonth}
        />
        <input
          hidden
          id="input_expirationYear"
          name="expirationYear"
          value={formValues.expirationYear}
        />
        <select hidden id="select_issuer" name="issuer" />
        <select hidden id="select_installments" name="installments" />
      </form>
    </>
  );
}

export default CreditCardDataCard;

import { Text, Box, Icon } from '@nimbus-ds/components';
import { LockIcon } from '@nimbus-ds/icons';
import { DataList } from '@nimbus-ds/patterns';
import { AiFeature } from '@tiendanube/common/src/enums';
import { Button } from '@tiendanube/components';
import { FEATURE_AI_TIER } from 'App/features';
import { Stack } from 'commons/components';
import Blur from 'commons/components/Blur';
import { useBoolean } from 'commons/hooks';
import { useUpsellFlow } from 'domains/Billing/UpsellFlow/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

const mockCategories = [
  {
    category_id: 1,
    category_path:
      '•••••••••••••••••••• > ••••••••••••••••• > ••••••••••••••••••',
  },
  {
    category_id: 2,
    category_path: '•••••••••••••••••••••••••• > •••••••••••••••••••••••••••',
  },
  {
    category_id: 3,
    category_path:
      '•••••••••••••••••••••••••••••••• > ••••••••••••••••••••••••••••••••••••••',
  },
];

function LockedSuggestedGoogleShoppingCategories() {
  const t = useTranslationCatalog(
    'categories.detail.googleShopping.suggestedCategories',
  );
  const [hover, setHover, unsetHover] = useBoolean(false);

  const handleOnClick = useUpsellFlow({
    title: t('upsellTitle'),
    featureKey: FEATURE_AI_TIER,
    trackingSource: AiFeature.AI_GOOGLE_SHOPPING_CATEGORIES,
    // Locked suggested categories are showed only for disabled users, so we never going to call this callback
    callback: undefined,
    minValue: 'tier-free',
  });

  return (
    <Box
      position="relative"
      onMouseEnter={() => setHover()}
      onMouseLeave={() => unsetHover()}
      onClick={handleOnClick}
      cursor="pointer"
    >
      <Blur>
        <div style={{ opacity: hover ? 1 : 0.5 }}>
          {mockCategories.map((value) => (
            <DataList.Row key={value.category_id} gap="1">
              <Stack justify="space-between">
                <Text fontSize="caption" color="neutral-textLow">
                  {value.category_path}
                </Text>
                <Button disabled>{t('cta')}</Button>
              </Stack>
            </DataList.Row>
          ))}
        </div>
      </Blur>
      <Box
        padding="3"
        position="absolute"
        top="calc(50% - 28px)"
        left="calc(50% - 28px)"
        backgroundColor="neutral-surface"
        borderRadius="full"
        borderColor="neutral-surfaceHighlight"
        borderStyle="solid"
        borderWidth="1"
      >
        <Icon source={<LockIcon />} />
      </Box>
    </Box>
  );
}

export default LockedSuggestedGoogleShoppingCategories;

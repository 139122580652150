import { useCallback, useEffect } from 'react';
import { useNavegate } from 'App/hooks';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
  Layout,
} from 'commons/components';
import { useToastStatus } from 'commons/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { Instructions } from './components';
import { useUploadPaymentProof } from './hooks';
import { useAfterPaymentRedirection, usePayorderToPay } from '../../hooks';
import { trackingBillingViewPayWireTransfer } from '../../tracking';
import { PayOrderSummary } from '../components';

function WireTransferPage() {
  const { goBack } = useNavegate();
  const { onCompletedPayment } = useAfterPaymentRedirection();
  const backNavigation = {
    onClick: goBack,
  };
  const t = useTranslationBilling('checkout.wireTransferPage');
  const { cleanStatus, status } = useUploadPaymentProof();

  const onSuccess = useCallback(() => {
    cleanStatus();
    onCompletedPayment();
  }, [onCompletedPayment, cleanStatus]);

  useToastStatus({
    status,
    error: t('error'),
    success: t('success'),
    onSuccess,
    onError: cleanStatus,
  });

  const { mainConcept } = usePayorderToPay();

  useEffect(() => {
    trackingBillingViewPayWireTransfer(mainConcept);
  }, [mainConcept]);

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={<HeaderContent title={t('title')} />}
      width="large"
    >
      <Layout
        left={<Instructions />}
        right={<PayOrderSummary showTaxes paymentMethod="transfer" />}
        orderMobile="reverse"
        mainContent
      />
    </IonPageStratus>
  );
}

export default WireTransferPage;

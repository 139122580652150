import { Upload } from 'tus-js-client';
import {
  ImportColumnsResponseDto,
  ImportFileRequestDto,
  ImportFileResponseDto,
  ResultPaginationResponseDto,
  SearchProductListResponseDto,
  ProductsTokenResponseDto,
  ProductTotalResponseDto,
  VariantResponseDto,
  UpdateStockResponseDto,
  ProductCloneResponseDto,
  ImageUploadResponseDto,
  ImportMaxLinesResponse,
  CustomPropertiesResponseDto,
  GenerateProductDescriptionRequestDto,
  GenerateProductDescriptionResponseDto,
  GenerateProductSeoRequestDto,
  GenerateProductSeoResponseDto,
  GenerateProductAltTextRequestDto,
  GenerateProductAltTextResponseDto,
  DownloadCsvTemplateResponseDto,
  VideoResponseDto,
} from '@tiendanube/common';
import axios from 'App/axios';
import {
  buildQueryParamsUrlWithSnakeCase,
  removeEmptyValues,
} from 'commons/utils';
import { getProductAdvancedDigest, sanitizateFilters } from './digest';
import {
  GetProductsType,
  UpdateProductsInterface,
  GetProductByIdType,
  UpdateProductInterface,
  DeleteProductsType,
  DeleteProductType,
  AddProductType,
  CloneProductType,
  EditPricesProductType,
  EditVariantsType,
  ExistsProductsWithoutWeightAndDimensionsType,
  GetStockHistoryType,
  GetSelectProductListType,
  ProductsFiltersType,
  EditStockType,
  AssignCategoriesType,
  UpdateInventoryLevelsType,
  FixesAttributesType,
  GetProductVariantsType,
} from './types';
import { statusSuccessType } from '../productsSlice/types';

const getProducts: GetProductsType = async ({ filters, version }) => {
  const params = sanitizateFilters(filters);
  const { data } = await axios.get(`v${version}/products`, {
    params: { ...params, perPage: '20' },
  });

  return {
    total: data.pagination.totalResults,
    sortBy: data.filter.sortBy,
    status: 'success',
    products: data.results,
  };
};

const getProductsAdvanced: GetProductsType = async ({
  filters,
  maxVariants,
  version,
}) => {
  const payload = getProductAdvancedDigest(filters);
  payload.per_page = '20';

  if (payload.page) {
    payload.page = String(payload.page);
  }

  if (maxVariants) {
    payload.max_variants = maxVariants;
  }

  const { data, headers } = await axios.post(
    `v${version}/products/advanced-search`,
    payload,
  );

  return {
    total: data.pagination.totalResults,
    status: headers['x-status'] as statusSuccessType,
    products: data.results,
    sortBy: data.filter.sortBy,
  };
};

const getProductVariants: GetProductVariantsType = async ({
  productId,
  version,
}) => {
  const { data } = await axios.get<VariantResponseDto[]>(
    `v${version}/products/${productId}/variants`,
  );
  return data;
};

const getProductById: GetProductByIdType = async ({ id, version }) => {
  const response = await axios.get(`v${version}/products/${id}`);
  if (response.data.name === 'Error') {
    throw Error('error');
  }
  return response.data;
};

const updateProducts: UpdateProductsInterface = async ({
  ids,
  product,
  version,
}) => {
  const response = await axios.patch(`v${version}/products`, {
    ids,
    action: product,
    version,
  });
  return response.data;
};

const updateProduct: UpdateProductInterface = async ({
  id,
  product,
  version,
}) => {
  const response = await axios.patch<any>(
    `v${version}/products/${id}`,
    product,
  );

  if (response.data.success === false) {
    throw Error('error');
  }
  return response.data;
};

const deleteProducts: DeleteProductsType = async (ids) => {
  await axios.delete(`v1/products?ids=${ids.join(',')}`);
};

const deleteProduct: DeleteProductType = async (id) => {
  await axios.delete(`v1/products/${id}`);
};

const addProduct: AddProductType = async (product) => {
  const response = await axios.post(`/v2/products`, product);

  if (response.data.code === 500 || response.data.name === 'Error') {
    throw Error('error');
  }
  return response.data;
};

const cloneProduct: CloneProductType = async ({
  version,
  ...payload
}): Promise<ProductCloneResponseDto> => {
  const { data } = await axios.post<ProductCloneResponseDto>(
    `v${version}/products/clone`,
    payload,
  );
  return data;
};

const editPricesProduct: EditPricesProductType = async (payload) => {
  const { data } = await axios.post(`v1/products/prices`, {
    ...payload,
    free_shipping: payload.freeShipping,
    max_stock: payload.maxStock,
    min_stock: payload.minStock,
    promotional_price: payload.promotionalPrice,
    weight_dimension: payload.weightDimension,
  });
  return data;
};

const assignCategories: AssignCategoriesType = async (payload) => {
  await axios.post(`v1/products/assign_categories`, payload);
};

const uploadVideo = async (file: File, accessToken: string) => {
  const baseURL = import.meta.env.VITE_API_BFF;

  return new Promise<number>((resolve, reject) => {
    let videoId: number;
    const endpoint = `${baseURL}/v1/media/videos/tus`;
    const upload = new Upload(file, {
      endpoint,
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onBeforeRequest: (req) => {
        if (req.getURL().includes(endpoint)) {
          req.setHeader('x-access-token', accessToken);
        }
      },
      onError: (error) => {
        reject(error);
      },
      onSuccess: () => {
        resolve(videoId);
      },
      onAfterResponse: async (req, res) => {
        const status = res.getStatus();
        if (req.getURL().includes(endpoint) && status >= 200 && status < 300) {
          const { id } = JSON.parse(res.getBody()) as { id: number };
          videoId = id;
        }
      },
    });
    upload.start();
  });
};

const getVideo = async (id: number) => {
  const { data } = await axios.get<VideoResponseDto>(`v1/media/videos/${id}`);
  return data;
};

const sleep = async (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

const uploadVideoFromFile = async (file: File, accessToken: string) => {
  const [MAX_RETRIES, SLEEP_TIME] = [36, 5000]; // retry 36 times every 5 seconds => 3 minutes
  // Upload the video and retrieve its ID
  const id = await uploadVideo(file, accessToken);
  // Wait SLEEP_TIME  before first call
  await sleep(SLEEP_TIME);
  let retries = 0;
  let videoData = await getVideo(id);
  // Retry while the status be 'pending'
  while (videoData.status === 'pending') {
    if (retries >= MAX_RETRIES) {
      throw new Error(
        `Video could not be processed after ${MAX_RETRIES} attempts`,
      );
    }
    retries++;
    // Wait SLEEP_TIME seconds before retrying
    await sleep(SLEEP_TIME);
    videoData = await getVideo(id);
  }
  if (videoData.status === 'failed') {
    throw new Error(`Video could not be processed by provider host`);
  }

  return {
    id: `${id}`,
    url: videoData.thumbnailUrl,
    videoUrl: videoData.videoUrl,
  };
};

const uploadImageFromBase64 = async (base64: string) => {
  const { data } = await axios.post<ImageUploadResponseDto>(
    '/v1/products/images/base64',
    {
      filename: '',
      attachment: base64,
    },
  );
  return data;
};

const uploadImageFromFile = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  const { data } = await axios.post<ImageUploadResponseDto>(
    '/v1/products/images/binary',
    formData,
  );
  return data;
};

const getStandardVariants = async () => {
  const { data } = await axios.get<CustomPropertiesResponseDto>(
    'v1/products/custom-properties',
  );
  return data;
};

const updateVariant: EditVariantsType = async ({ productId, variant }) => {
  const { data } = await axios.patch<VariantResponseDto>(
    `/v1/products/${productId}/variants`,
    variant,
  );

  return data;
};

const updateStock: EditStockType = async ({
  action,
  value,
  productId,
  variantId,
  locationId,
  cause,
}) => {
  const { data } = await axios.post<UpdateStockResponseDto[]>(
    `/v1/products/${productId}/variants/stock`,
    { action, value, id: variantId, location_id: locationId, cause },
  );

  return data;
};

const existsProductsWithoutWeightAndDimensions: ExistsProductsWithoutWeightAndDimensionsType =
  async () => {
    const {
      data: { exists },
    } = await axios.get(`/v1/products/has-weight-and-dimensions`);
    return exists;
  };

const getStockHistory: GetStockHistoryType = async ({
  variantId,
  productId,
  locationId,
}) => {
  const params = { locationId: locationId };
  const { data } = await axios.get(
    `/v1/products/${productId}/variants/${variantId}/stock`,
    { params },
  );

  return data;
};

export const getSelectProductList: GetSelectProductListType = async (
  params,
) => {
  try {
    const { data } = await axios.get<
      Promise<ResultPaginationResponseDto<SearchProductListResponseDto[]>>
    >(`v1/products/search`, {
      ...(params && { params }),
    });
    return data;
  } catch (error) {
    if (error?.isAxiosError && error.response?.status === 404) {
      throw new Error('404');
    }
    throw new Error(error?.response?.status || '500');
  }
};

const fetchTotalProducts = async (
  filtersParams: ProductsFiltersType,
): Promise<number> => {
  const paramsToRequest: ProductsFiltersType = { ...filtersParams };
  const sanitizateFilters: Record<string, string> =
    removeEmptyValues(paramsToRequest);

  const params = buildQueryParamsUrlWithSnakeCase(sanitizateFilters);

  const { data } = await axios.get<ProductTotalResponseDto>(
    `/v1/products/total?${params}`,
  );
  return data.total;
};

const exportProducts = async (
  filtersParams: ProductsFiltersType,
): Promise<ProductsTokenResponseDto> => {
  const params = {
    category_id: filtersParams.categoryId,
    published: filtersParams.published,
    free_shipping: filtersParams.freeShipping,
    stock: filtersParams.stock,
    min_stock: filtersParams.minStock || undefined,
    max_stock: filtersParams.maxStock || undefined,
    promotional_price: filtersParams.promotionalPrice,
    weight_dimension: filtersParams.weightDimension,
    include_descriptions: filtersParams.includeDescriptions,
    sort_by: filtersParams.sortBy,
  };

  const { data } = await axios.post<ProductsTokenResponseDto>(
    `/v1/products/export`,
    params,
  );
  return data;
};

const fetchTemplateCsv = async (): Promise<DownloadCsvTemplateResponseDto> => {
  const { data } = await axios.get<DownloadCsvTemplateResponseDto>(
    `/v1/products/import/template`,
  );
  return data;
};

const fetchImportColumns = async (): Promise<ImportColumnsResponseDto> => {
  const { data } = await axios.get<ImportColumnsResponseDto>(
    `/v1/products/import/columns`,
  );
  return data;
};

const importFile = async ({
  file,
  replace,
  columns,
  columnsChanged,
}: ImportFileRequestDto): Promise<ImportFileResponseDto> => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('replace', replace ? 'true' : 'false');
  formData.append('columns', columns as string);
  formData.append('columnsChanged', columnsChanged ? 'true' : 'false');
  formData.append('sendEntityEmail', 'false');

  const { data } = await axios.post<ImportFileResponseDto>(
    `/v1/products/import/file`,
    formData,
  );
  return data;
};

const updateInventoryLevels: UpdateInventoryLevelsType = async ({
  productId,
  variantId,
  inventoryLevels,
}) => {
  const { data } = await axios.patch(`/v1/products/${productId}/variants`, {
    id: variantId,
    inventoryLevels,
  });
  return data;
};

const getImportMaxLines = async () => {
  const { data } = await axios.get<ImportMaxLinesResponse>(
    '/v1/products/import/max-lines',
  );
  return data;
};

const generateProductDescription: (
  requestDto: GenerateProductDescriptionRequestDto,
) => Promise<GenerateProductDescriptionResponseDto> = async (requestDto) => {
  const response = await axios.post(
    `/v1/products/generate/description`,
    requestDto,
  );
  return response.data;
};

const generateProductSeo: (
  requestDto: GenerateProductSeoRequestDto,
) => Promise<GenerateProductSeoResponseDto> = async (requestDto) => {
  const response = await axios.post(`/v1/products/generate/seo`, requestDto);
  return response.data;
};

const generateProductAltText: (
  requestDto: GenerateProductAltTextRequestDto,
) => Promise<GenerateProductAltTextResponseDto> = async (requestDto) => {
  const response = await axios.post(
    `/v1/products/generate/alt-text`,
    requestDto,
  );
  return response.data;
};

const fixesAttributesVariants = async ({
  id,
  fixAttributes,
}: FixesAttributesType) => {
  await axios.put<void>(`/v1/products/${id}/fix-attributes`, fixAttributes);
};

const fetchTotalTags = async () => {
  const { data } = await axios.get<string[]>('/v1/products/tags');
  return data;
};

const catalogServices = {
  updateProduct,
  getProductById,
  deleteProducts,
  deleteProduct,
  getProductVariants,
  getProducts,
  getProductsAdvanced,
  addProduct,
  updateProducts,
  cloneProduct,
  editPricesProduct,
  assignCategories,
  updateVariant,
  updateStock,
  existsProductsWithoutWeightAndDimensions,
  getStockHistory,
  getSelectProductList,
  fetchTotalProducts,
  exportProducts,
  fetchTemplateCsv,
  fetchImportColumns,
  importFile,
  getStandardVariants,
  updateInventoryLevels,
  uploadVideo,
  uploadVideoFromFile,
  getVideo,
  uploadImageFromBase64,
  uploadImageFromFile,
  getImportMaxLines,
  generateProductDescription,
  generateProductSeo,
  generateProductAltText,
  fixesAttributesVariants,
  fetchTotalTags,
};

export default catalogServices;

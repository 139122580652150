export const CHILE = {
  THEMES: {
    id: 3286,
    name: '🟣 Landing Page',
    description: 'Crea páginas de alta conversión para tu ecommerce',
    image: '3286-es_AR-3286-pt_BR-small-Gif%20LP.gif',
    urlText: 'Más apps de recursos extra',
    categoryUri: 'recursos-extra',
    handle: 'landing-page1',
  },
  ABANDONED_CARTS: {
    id: 738,
    name: 'Marketing Nube (ex Perfit)',
    description:
      'Vende más con automatización de marketing de Tiendanube: email marketing, recupero de carritos, asis...',
    image: '738-es_AR-small-share-fb-2.png',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
    handle: 'perfit',
  },
  MESSAGES: {
    id: 1163,
    name: 'JivoChat',
    description: 'Chat multicanal de soporte para aumentar tus ventas',
    image:
      '1163-es_AR-small-1163-es_AR-small-567-pt_BR-small-jivochat_icon.jpg',
    urlText: 'Más apps de comunicacion',
    categoryUri: 'comunicacion',
    handle: 'jivochat-ar',
  },
  DISCOUNT: {
    id: 3538,
    name: 'HintUP Promociones',
    description:
      'Promociones con: Descuentos especiales, progresivos, regalos, cupón de primera compra, kits, UTM y m...',
    image: '3538-es_AR-marca_com_r.png',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
    handle: 'hintup-promocoes',
  },
  COUPONS: {
    id: 11660,
    name: 'Magic Coupons | Appsnube',
    description:
      'Magic Coupons permite generar links con cupones de descuento embebidos.',
    image: '11660-es_AR-logo_autocupon.gif',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
    handle: 'coupon-links-appsnube',
  },
  FREE_SHIPPING: undefined,
  PROMOTIONS: {
    id: 7719,
    name: 'Promociones combinadas by TITANPush',
    description:
      'Con Promociones Combinada vas a poder ofrecer un descuento porcentual o fijo + envío gratis a tus us...',
    image: '7719-es_AR-logo%20-%20promociones%20combinadas%20(1).png',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
    handle: 'promociones-combinadas-by-titanpush',
  },
  PRODUCTS: {
    id: 9746,
    name: 'Dropify',
    description:
      'Importa productos en masa utilizando lasDropi facilita dropshipping y envíos en CO, CL, y MX, integrando transportadoras y gestionando pagos...',
    image: '9746-es…2065650_600342888177323_2188260863749335634_n.jpg',
    urlText: 'Más apps de dropshipping',
    categoryUri: 'dropshipping',
    handle: 'dropi-app',
  },
  CUSTOMERS: {
    id: 4044,
    name: 'HubSpot Integration by HAL',
    image: '4044-es_AR-small-logo 600x600.png',
    handle: 'hubspot-integration-test',
    description:
      'Lleva el control sobre tus negocios y administra los datos de tus clientes, pedidos, productos y carritos.',
    urlText: 'Más apps de canales de venta',
    categoryUri: 'canales-de-venta',
  },
  ORDERS: {
    id: 11716,
    name: 'Envíos Digitales',
    image: '11716-es_AR-icon.png',
    handle: 'envios-digitales',
    description:
      'Envíos Digitales envía automáticamente por email links de descarga a tus productos digitales.',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
  },
  DRAFT_ORDERS: {
    id: 2283,
    name: 'Astroselling',
    image: '2283-es_AR-TN-logo_nuevo 1.gif',
    handle: 'astroselling',
    description:
      'Sincroniza y publica de forma automática en Mercado Libre y más, como Falabella, Ripley, Paris y otros',
    urlText: 'Más apps de canales de venta',
    categoryUri: 'canales-de-venta',
  },
  EMAILS: undefined,
  INFO_MESSAGES: {
    id: 5642,
    name: 'Tagy',
    image: '5642-es_AR-5642-pt_BR-LOGO---Tagy1.png',
    handle: 'tagy',
    description:
      'Sellos, Etiquetas, Botones de WhatsApp y mucha personalización.',
    urlText: 'Más apps de marketing',
    categoryUri: 'marketing',
  },
};

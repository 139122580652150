import { useEffect } from 'react';
import { Text, Alert } from '@nimbus-ds/components';
import { DataList } from '@nimbus-ds/patterns';
import { Button } from '@tiendanube/components';
import { Stack } from 'commons/components';
import { AiIcon } from 'commons/components/GenerateWithAiLink';
import useSuggestedGoogleShoppingCategories from 'domains/Ai/hooks/useSuggestedGoogleShoppingCategories';
import useSupportsSuggestedGoogleShoppingCategories from 'domains/Ai/hooks/useSupportsSuggestedGoogleShoppingCategories';
import { trackingAiGoogleShoppingCategoryUseClick } from 'domains/Ai/tracking';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import LockedSuggestedGoogleShoppingCategories from './components/LockedSuggestedGoogleShoppingCategories';
import SuggestedCategoriesListSkeleton from './SuggestedCategoriesListSkeleton';

interface SuggestedGoogleShoppingCategoriesProps {
  categoryId: string;
  name: string;
  description?: string;
  onUseGoogleShoppingCategory: (googleShoppingCategorySelected: string) => void;
}

function SuggestedGoogleShoppingCategories({
  categoryId,
  name,
  description,
  onUseGoogleShoppingCategory,
}: Readonly<SuggestedGoogleShoppingCategoriesProps>): JSX.Element {
  const t = useTranslationCatalog(
    'categories.detail.googleShopping.suggestedCategories',
  );
  const {
    generateSuggestedCategories,
    suggestedCategories,
    loading,
    error,
    versions,
  } = useSuggestedGoogleShoppingCategories();

  const supportSuggestedGoogleShoppingCategories =
    useSupportsSuggestedGoogleShoppingCategories();

  useEffect(() => {
    if (supportSuggestedGoogleShoppingCategories && !error) {
      generateSuggestedCategories({
        categoryId,
        name: name,
        description: description,
      });
    }
  }, [
    supportSuggestedGoogleShoppingCategories,
    generateSuggestedCategories,
    error,
    categoryId,
    name,
    description,
  ]);

  const handleOnUse = (
    position: number,
    googleShoppingCategorySelected: string,
  ) => {
    trackingAiGoogleShoppingCategoryUseClick(position, { versions });
    onUseGoogleShoppingCategory(googleShoppingCategorySelected);
  };

  return (
    <Stack column gap="2" align="flex-start">
      <Stack align="center" gap="1">
        <AiIcon />
        <Text fontSize="base" color="primary-interactive" textAlign="left">
          {t('title')}
        </Text>
      </Stack>
      {error && (
        <Alert appearance="warning" title={t('errorAlert.title')}>
          <Text color="neutral-textLow">{t('errorAlert.message')}</Text>
        </Alert>
      )}
      {!error && (
        <DataList>
          {loading && <SuggestedCategoriesListSkeleton />}
          {!loading && !supportSuggestedGoogleShoppingCategories && (
            <LockedSuggestedGoogleShoppingCategories />
          )}
          {!loading &&
            supportSuggestedGoogleShoppingCategories &&
            suggestedCategories.map((value, index) => (
              <DataList.Row key={value.category_id} gap="1">
                <Stack justify="space-between">
                  <Text fontSize="caption" color="neutral-textLow">
                    {value.category_path}
                  </Text>
                  <Button
                    onClick={() => handleOnUse(index, value.category_path)}
                  >
                    {t('cta')}
                  </Button>
                </Stack>
              </DataList.Row>
            ))}
        </DataList>
      )}
    </Stack>
  );
}

export default SuggestedGoogleShoppingCategories;

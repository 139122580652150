import { Icon, Tag } from '@nimbus-ds/components';
import { StarIcon } from '@tiendanube/icons';

interface MainImageTagProps {
  text?: string;
}
function MainImageTag({ text }: Readonly<MainImageTagProps>) {
  return (
    <div className="stratus--main-image-tag">
      <Tag appearance="primary">
        <Icon color="primary-textLow" source={<StarIcon size={12} />} />
        {text}
      </Tag>
    </div>
  );
}

export default MainImageTag;

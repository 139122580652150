import { ChangeEvent, useState } from 'react';
import { Box, FileUploader } from '@nimbus-ds/components';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { AlertImagesValidation, useResponsive } from 'commons/components';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import FileDraggable from 'domains/Catalog/Products/pages/components/FileDraggable';
import { useImageLoader } from 'domains/Catalog/Products/pages/hooks';
import { trackingProductDropImages } from 'domains/Catalog/Products/tracking';
import { MediaGalleryState } from '../../ImageGallery';

import './MediaDropArea.scss';

interface MediaDropAreaProps {
  isEdit: boolean;
  videoQuantity: number;
  onClick: () => void;
  onUploadImages: (images: MediaGalleryState[]) => void;
}

function MediaDropArea({
  isEdit,
  videoQuantity,
  onClick,
  onUploadImages,
}: Readonly<MediaDropAreaProps>): JSX.Element {
  const t = useTranslationCatalog('products.detail');
  const sender = useTrackFullCatalog();

  const { isDesktop } = useResponsive();
  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);
  const [timesNewElement, setTimesNewElement] = useState(1);

  const step1 = hasUploadVideos ? 'photosAndVideo' : 'onlyPhotos';
  const step2 = isDesktop ? 'dragAndDropOrSelect' : 'select';
  const text = t(`${step1}.${step2}`);

  const {
    imagesWithBadType,
    imagesExceededSize,
    videosExceededSize,
    videosExceededQuantity,
    acceptTypeFile,
    setImagesExceededSize,
    setImagesWithBadType,
    setVideosExceededSize,
    setVideosExceededQuantity,
    handleOnchange,
  } = useImageLoader({
    onUploadImages,
    videoQuantity,
  });

  const handleOnDrop = (files: File[]) => {
    setImagesExceededSize([]);
    setImagesWithBadType([]);
    setVideosExceededSize([]);
    setVideosExceededQuantity(false);
    handleOnchange(files);
    sender(() => {
      trackingProductDropImages({
        from: 'card',
        count: files.length,
        isEdit,
      });
    });
  };

  const handleChangeFiles = async (event: ChangeEvent<HTMLInputElement>) => {
    setTimesNewElement((value) => value + 1);
    const files = event.target.files;
    if (files) {
      handleOnchange(Array.from(files));
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="2-5">
      <AlertImagesValidation filenames={imagesWithBadType} validation="type" />
      <AlertImagesValidation filenames={imagesExceededSize} validation="size" />
      <AlertImagesValidation
        filenames={videosExceededSize}
        validation="videoSize"
      />
      {videosExceededQuantity && (
        <AlertImagesValidation filenames={['']} validation="videoQuantity" />
      )}
      <FileDraggable onDropFiles={handleOnDrop}>
        <div className="stratus--media-drop-area opacable">
          <FileUploader
            key={timesNewElement}
            // This key allows re-selecting the same file (otherwise onChange won't fire).
            // Assigning a new key on each onChange forces a re-render.
            placeholder={text}
            aspectRatio="none"
            height="88px"
            onChange={handleChangeFiles}
            accept={acceptTypeFile}
            onClick={onClick}
            multiple
          />
        </div>
      </FileDraggable>
    </Box>
  );
}

export default MediaDropArea;

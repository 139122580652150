import { Icon, IconButton, Tooltip } from '@nimbus-ds/components';
import { TrashIcon, EditIcon, PlayIcon } from '@nimbus-ds/icons';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Thumbnail } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import MainImageTag from './MainImageTag';
import { ImageGalleryState } from '../../../ImageGallery';

import './MediaPreviewItem.scss';

interface ThumbnailItemProps {
  image: ImageGalleryState;
  alt: string;
  src: string;
  showMainTag: boolean;
  aspectRatio: '16-9' | '4-3';
  onError: () => void;
  onRemove: (imageId: string) => void;
  onEdit: (imageId: string) => void;
  onClickVideo: () => void;
}

function ThumbnailItem({
  image,
  alt,
  showMainTag,
  aspectRatio,
  onEdit,
  onError,
  onRemove,
  onClickVideo,
}: Readonly<ThumbnailItemProps>) {
  const { t } = useTranslation(Domain.CATALOG);
  const { isDesktop } = useResponsive();

  const { isLoading, isError } = image;
  const showDelete = !isLoading;
  const showEdit = !isError && !isLoading && image.mediaType === 'image';
  const showVideo = image.mediaType === 'video' && !!image.publicUrl;

  return (
    <div className="stratus--media-preview__item">
      {image.isError ? (
        <Thumbnail.Error
          onClick={onError}
          ariaLabel={t('products.detail.errorUploadPhoto')}
          src={image.src}
          altText={alt}
          aspectRatio={aspectRatio}
        />
      ) : (
        <Thumbnail
          loading={image.isLoading}
          src={image.src}
          altText={alt}
          aspectRatio={aspectRatio}
        />
      )}
      {showVideo && (
        <div className="stratus--item-video">
          <Icon
            color="neutral-background"
            onClick={onClickVideo}
            source={<PlayIcon size={40} />}
          />
        </div>
      )}
      <div className="stratus--item-actions">
        {showEdit && isDesktop && (
          <Tooltip content={t('products.detail.editPhoto')}>
            <IconButton
              type="button"
              size="2rem"
              source={<EditIcon />}
              onClick={() => onEdit(image.id)}
            />
          </Tooltip>
        )}
        {showDelete && isDesktop && (
          <Tooltip
            content={
              image.mediaType === 'image'
                ? t('products.detail.deletePhoto')
                : t('products.detail.deleteVideo')
            }
          >
            <IconButton
              type="button"
              size="2rem"
              source={<TrashIcon />}
              onClick={() => onRemove(image.id)}
            />
          </Tooltip>
        )}
      </div>
      {showMainTag && (
        <MainImageTag text={isDesktop ? t('products.detail.mainImage') : ''} />
      )}
    </div>
  );
}

export default ThumbnailItem;

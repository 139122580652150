import { useCallback, useEffect } from 'react';
import { Box, Button, Spinner, Text } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { useToastStatus } from 'commons/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import { useStoreStatus } from 'domains/Auth/hooks';
import { usePaymentMethods } from 'domains/Billing/Checkout/hooks';
import { trackingBillingStartPayment } from 'domains/Billing/Checkout/tracking';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useChargeCreation } from './hooks';
import { ChargeToPay } from '../';
import { useSelectCharges } from '../../hooks';
import SelectChargesToPayTabSkeleton from '../../Skeleton';

export default function SelectChargesWithCreation() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );
  const {
    onContinue,
    isLoading: isLoadingPayment,
    status: continuePaymentStatus,
  } = useChargeCreation();
  const formatCurrency = useFormatCurrency();
  const { status, firstRecurrencyOptionAsCharge, refreshPaymentMethods } =
    usePaymentMethods();
  const {
    chargesToSelect,
    totalOfSelectedCharges,
    chargesStatus,
    refreshChargesToPay,
    selectedCharges,
    setSelectedCharges,
  } = useSelectCharges();

  useEffect(() => {
    firstRecurrencyOptionAsCharge &&
      chargesToSelect &&
      setSelectedCharges([
        firstRecurrencyOptionAsCharge,
        ...chargesToSelect.filter((charge) => charge.status !== 'IN_PROCESS'),
      ]);
  }, [setSelectedCharges, firstRecurrencyOptionAsCharge, chargesToSelect]);

  useToastStatus({
    status: continuePaymentStatus,
    error: t('continuePaymentError'),
  });

  const { isLoading, isError } = combineStatus(status, chargesStatus);
  const retryLoad = useCallback(() => {
    refreshPaymentMethods();
    refreshChargesToPay();
  }, [refreshPaymentMethods, refreshChargesToPay]);

  const { storeStatus } = useStoreStatus();

  const handleGoToPay = () => {
    trackingBillingStartPayment(storeStatus === 'store_down', 'plan-cost');
    onContinue(selectedCharges);
  };

  if (isLoading) return <SelectChargesToPayTabSkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{ children: t('error.retry'), onClick: retryLoad }}
      />
    );

  if (!firstRecurrencyOptionAsCharge) return null;

  return (
    <Box display="flex" flexDirection="column">
      <ChargeToPay charge={firstRecurrencyOptionAsCharge} isVirtualCharge />
      <hr />
      {chargesToSelect?.map((charge) => (
        <>
          <ChargeToPay charge={charge} />
          <hr />
        </>
      ))}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="4"
        alignItems="center"
      >
        <Button
          appearance="primary"
          onClick={handleGoToPay}
          disabled={isLoadingPayment}
        >
          {t('continueToPay')}
          {isLoadingPayment && <Spinner size="small" />}
        </Button>
        <Box display="flex" flexDirection="column">
          <Text fontSize="caption">{t('total')}</Text>
          <Text color="primary-textHigh" fontSize="highlight" fontWeight="bold">
            {formatCurrency(
              totalOfSelectedCharges,
              firstRecurrencyOptionAsCharge.amountCurrency,
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

import { COUNTRIES_WITH_GEOLOCATION_INFO } from 'domains/Shipping/utils';

export type RangeType = 'all-country' | 'zones' | 'ranges';

export const getRangeTypeOptions = (country: string) => {
  const baseOptions = [
    {
      label: 'options.allCountry',
      value: 'all-country' as RangeType,
    },
    {
      label: 'options.byZones',
      value: 'zones' as RangeType,
    },
  ];

  if (COUNTRIES_WITH_GEOLOCATION_INFO.has(country)) {
    return [
      ...baseOptions,
      {
        label: 'options.byRanges',
        value: 'ranges' as RangeType,
      },
    ];
  }

  return baseOptions;
};

import { CSSProperties } from 'react';
import { Alert, Text } from '@nimbus-ds/components';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';
import { useMediaUploadStatus } from '../../../MediaUploadStatus';

interface AlertMediaStatusProps {
  isFormSubmitted: boolean;
}

function AlertMediaStatus({
  isFormSubmitted,
}: Readonly<AlertMediaStatusProps>) {
  const t = useTranslationCatalog('products.detail.alertMediaStatus');
  const { isUploading } = useMediaUploadStatus();

  if (!isUploading) return null;

  // Hidden via CSS, because must exist in the DOM
  // data-block-submit-error` ensures scrolling when submitting the form
  const styles: CSSProperties = {
    visibility: isFormSubmitted ? 'visible' : 'hidden',
    position: isFormSubmitted ? 'static' : 'absolute',
    width: isFormSubmitted ? '100%' : '0',
  };

  return (
    <div style={styles}>
      <Alert
        title={t('title')}
        appearance="danger"
        data-block-submit-error="true"
      >
        <Text>{t('body')}</Text>
      </Alert>
    </div>
  );
}

export default AlertMediaStatus;

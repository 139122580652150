import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ImageUploadResponseDto } from '@tiendanube/common';
import { getAccessToken } from 'domains/Auth/authSlice/authSelectors';
import productsServices from 'domains/Catalog/Products/productsServices';

interface FileMedia {
  id: string;
  mediaType: 'video' | 'image';
  file: File;
  base64?: never;
}

interface Base64Media {
  id: string;
  mediaType: 'base64';
  file?: never;
  base64: string;
}

export type MediaConentType = FileMedia | Base64Media;

interface AddMediaResponseDto extends ImageUploadResponseDto {
  videoUrl?: string;
}

export type addMediaType = (
  media: MediaConentType,
) => Promise<AddMediaResponseDto>;

function useMedia() {
  const accessToken = useSelector(getAccessToken) ?? '';

  const addMedia: addMediaType = useCallback(
    async (media) => {
      switch (media.mediaType) {
        case 'video':
          return productsServices.uploadVideoFromFile(media.file, accessToken);
        case 'image':
          return productsServices.uploadImageFromFile(media.file);
        case 'base64':
          return productsServices.uploadImageFromBase64(media.base64);
      }
    },
    [accessToken],
  );

  return { addMedia };
}

export default useMedia;

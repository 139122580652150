import { FulfillmentOrderLineItemForDocumentResponseDto } from '@tiendanube/common';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';

interface ProductsDocumentPreviewProps {
  lineItems: FulfillmentOrderLineItemForDocumentResponseDto[];
  showTotalPrices?: boolean;
  showUnitPrices?: boolean;
  showSku?: boolean;
}

function ProductsDocumentPreview({
  lineItems,
  showTotalPrices = true,
  showUnitPrices = true,
  showSku = true,
}: Readonly<ProductsDocumentPreviewProps>) {
  const t = useTranslationFulfillmentOrders('documents.general.lineItems');
  return (
    <div className="products">
      <div className="product-row">
        <div className="product-row-info">
          <p className="bold">{t('name')}</p>
          {showTotalPrices && <p className="bold">{t('price')}</p>}
          {!showTotalPrices && showUnitPrices && (
            <p className="bold">{t('quantityAndUnitPrice')}</p>
          )}
        </div>
      </div>
      {lineItems.map(
        ({ name, quantity, totalPrice, unitPrice, sku, properties }) => (
          <div key={name} className="product-row">
            <div className="product-row-info">
              <div
                className={`product-name ${
                  (showUnitPrices && !showTotalPrices) ||
                  (!showUnitPrices && showTotalPrices)
                    ? 'partial-price'
                    : ''
                } ${!showUnitPrices && !showTotalPrices ? 'no-price' : ''}`}
              >
                <p>{name}</p>
                {showSku && !!sku && (
                  <p className="product-sku">
                    {t('sku')} {sku}
                  </p>
                )}
              </div>
              <p className="product-quantity">
                {showUnitPrices && `${quantity} x ${unitPrice}`}
              </p>
              {showTotalPrices && (
                <p className="product-total bold">{totalPrice}</p>
              )}
            </div>
            {properties?.map(({ key, value }) => (
              <p key={sku + key} className="product-property">
                {key}: {value}
              </p>
            ))}
          </div>
        ),
      )}
    </div>
  );
}

export default ProductsDocumentPreview;

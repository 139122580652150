import { Title, Tag, Box, Link, Icon } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { CardCollapsible, Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import {
  TypesRadio,
  NcmAndCest,
  OriginSelect,
  TaxRuleField,
  InvoiceAlert,
} from './components';

function InvoiceCard() {
  const t = useTranslationCatalog('products.detail.invoice');
  ////TODO:provisional data that will come from the service
  const hasTaxNote = false;

  return (
    <CardCollapsible
      title={
        <Box
          display="flex"
          justifyContent="space-between"
          width="100%"
          alignItems="center"
        >
          <Title as="h4">{t('title')}</Title>
          <Box paddingRight="2">
            <Tag appearance="primary">{t('tag')}</Tag>
          </Box>
        </Box>
      }
      active={false}
      cursorEnable
    >
      <Stack column align="stretch" spacing="base">
        {!hasTaxNote && <InvoiceAlert />}
        <OriginSelect />
        <TypesRadio />
        <NcmAndCest />
        <TaxRuleField hasTaxRule />
        <Stack.Item>
          <Link
            as="a"
            href="https://nimbus.tiendanube.com/"
            target="_blank"
            appearance="neutral"
            textDecoration="underline"
          >
            {t('helpLink')}
            <Icon source={<ExternalLinkIcon />} color="currentColor" />
          </Link>
        </Stack.Item>
      </Stack>
    </CardCollapsible>
  );
}

export default InvoiceCard;

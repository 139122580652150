import { useRef, useState, useEffect } from 'react';
import { Box, Link, Tag, Text } from '@nimbus-ds/components';
import { EditIcon } from '@nimbus-ds/icons';
import { i18nResponseDto } from '@tiendanube/common';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { CardWithTitle } from 'commons/components';
import { useToast } from 'commons/hooks';
import {
  useTrackFullCatalog,
  useTranslationCatalog,
} from 'domains/Catalog/hooks';
import {
  trackingProductDetailImageClick,
  trackingProductDetailImageError,
  trackingProductDetailImageSuccess,
} from 'domains/Catalog/Products/tracking';
import {
  ImageGalleryModal,
  AlertCloneStatus,
  AlertMediaStatus,
  MediaDropArea,
  UploadingMessage,
  RecommendationsGridRow,
  MediaPreview,
  Video,
} from './components';
import { AltTextGenerationContext } from './components/EditImageModal/EditImageActions/components/EditAltTextModal/components/AltTextGenerationModal';
import useMedia, { MediaConentType } from './useMedia';
import { useMediaUploadStatus } from '../MediaUploadStatus';

export interface ImageGalleryState {
  isLoading: boolean;
  isError: boolean;
  id: string;
  src: string;
  alt: i18nResponseDto;
  name: string;
  file?: File;
  publicUrl?: string; // May be different from src if it's a freshly uploaded image using a blob as its src
  isEdited: boolean;
  replacedImageId?: string; // Used to store the id of the image that will be replaced by this one
  base64?: string; // Used to store the base64 of the image when it's edited and not uploaded yet
  type: string;
  size: number;
  mimeType: string;
  mediaType: 'video' | 'image';
  videoUrl: string;
}

export type MediaGalleryState = ImageGalleryState;

interface ImageGalleryProps {
  onClickVideo: () => void;
  onChange: (images: ImageGalleryState[], id?: string) => void;
  onChangeExternalVideoUrl: ({
    name,
    value,
  }: {
    name: string;
    value: string;
  }) => void;
  images: ImageGalleryState[];
  videoUrl?: string;
  altTextGenerationContext: AltTextGenerationContext;
  isEdit?: boolean;
  isFormSubmitted: boolean;
  errors: Partial<Record<string, string>>;
}

// eslint-disable-next-line max-statements
function ImageGallery({
  onClickVideo,
  onChange,
  onChangeExternalVideoUrl,
  images,
  videoUrl,
  altTextGenerationContext,
  isEdit,
  isFormSubmitted,
  errors,
}: Readonly<ImageGalleryProps>): JSX.Element {
  const sender = useTrackFullCatalog();
  const t = useTranslationCatalog();
  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);
  const { addMedia } = useMedia();
  const { addToast } = useToast();
  const imagesRef = useRef<ImageGalleryState[]>(images);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isOpenFileSystem, setIsOpenFileSystem] = useState(false);
  const [imageIdForEditOnOpen, setImageIdForEditOnOpen] = useState<
    string | undefined
  >();
  const [totalToUpload, setTotalToUpload] = useState(0);
  const [countLoadSuccess, setCountLoadSuccess] = useState(0);
  const [countLoadError, setCountLoadError] = useState(0);

  const { start: startMediaUpload, stop: stopMediaUpload } =
    useMediaUploadStatus();

  useEffect(() => {
    imagesRef.current = images;
  }, [images]);

  useEffect(() => {
    const isNotFinishedUpload =
      totalToUpload === 0 ||
      totalToUpload !== countLoadError + countLoadSuccess;

    if (isNotFinishedUpload) return;
    resetCount();
    stopMediaUpload();
    if (countLoadError === 0) {
      sender(() => {
        trackingProductDetailImageSuccess(isEdit);
      });
      addToast({
        label: t('products.toast.uploadPhotosSuccess'),
        appearance: 'success',
      });
      return;
    }
    if (countLoadSuccess === 0) {
      sender(() => {
        trackingProductDetailImageError(isEdit);
      });
      addToast({
        label: t('products.toast.uploadPhotosError'),
        appearance: 'danger',
      });
      return;
    }
    addToast({
      label: t('products.toast.somePhotosNotUploaded'),
      appearance: 'warning',
    });
  }, [
    totalToUpload,
    countLoadError,
    countLoadSuccess,
    t,
    addToast,
    sender,
    isEdit,
    stopMediaUpload,
  ]);

  const resetCount = () => {
    setCountLoadError(0);
    setCountLoadSuccess(0);
    setTotalToUpload(0);
  };

  const handleOnCloseModal = () => {
    setIsOpenFileSystem(false);
    setIsShowModal(false);
  };
  const handleOnOpenModal = () => {
    setIsShowModal(true);
  };

  const updateImageStatus = (
    id: string,
    update: Partial<ImageGalleryState>,
  ) => {
    const image = imagesRef.current.find((image) => image.id === id);
    if (!image) return;
    onChange([{ ...image, ...update }], id);
  };

  const handleAddMedia = async (media: MediaConentType) => {
    try {
      const { id, url, videoUrl = '' } = await addMedia(media);
      updateImageStatus(media.id, {
        id: `${id}`,
        ...(media.mediaType === 'video' && { src: url, videoUrl }),
        publicUrl: url,
        isError: false,
        isLoading: false,
      });
      setCountLoadSuccess((count) => count + 1);
    } catch (error) {
      console.error(error);
      setCountLoadError((count) => count + 1);
      updateImageStatus(media.id, {
        isError: true,
        isLoading: false,
      });
    }
  };

  const handleAddMedias = async (newImages: ImageGalleryState[]) => {
    if (!newImages.length) return;
    startMediaUpload();
    setTotalToUpload((total) => total + newImages.length);
    newImages.forEach((image) => {
      if (image.isEdited && !!image.base64) {
        handleAddMedia({
          id: image.id,
          base64: image.base64,
          mediaType: 'base64',
        });
        return;
      }

      if (!image.file) return;

      handleAddMedia({
        id: image.id,
        file: image.file,
        mediaType: image.mediaType,
      });
    });
  };

  const onRetryError = () => {
    const imagesWithError = imagesRef.current.filter((image) => image.isError);
    handleAddMedias(imagesWithError);
    onChange([
      ...imagesRef.current.map((image) => {
        if (image.isError) {
          return { ...image, isError: false, isLoading: true };
        }
        return image;
      }),
    ]);
  };

  const handleOnchange = (newImages: ImageGalleryState[]) => {
    const imagesWithReplacements = imagesRef.current.map((image) => {
      const replacementImage = newImages.find(
        (newImage) => image.id === newImage.replacedImageId,
      );
      return replacementImage || image;
    });

    onChange([
      ...imagesWithReplacements,
      ...newImages
        .filter((i) => i.replacedImageId === undefined)
        .map((i) => ({ ...i, alt: {} })),
    ]);
    handleAddMedias(newImages);
  };

  const handleOnRemove = (imageId: string) =>
    onChange(images.filter((image) => image.id !== imageId));

  const handleOnEdit = (imageId: string) => {
    setImageIdForEditOnOpen(imageId);
    handleOnOpenModal();
  };

  const onOpenEditModal = () => setImageIdForEditOnOpen(undefined);
  const handleOnClickAdd = () => {
    trackingProductDetailImageClick(isEdit);
  };

  const message = totalToUpload
    ? t('products.detail.uploadingPhotosStatus', {
        photo: countLoadSuccess + 1,
        total: totalToUpload,
      })
    : '';

  const title = hasUploadVideos
    ? t('products.detail.media')
    : t('products.detail.photos');

  const videoQuantity = images.filter(
    (file) => file.mediaType === 'video',
  ).length;

  return (
    <CardWithTitle
      title={title}
      type="h4"
      tag={
        hasUploadVideos && (
          <Tag id="video-beta-tag" appearance="primary">
            <Text
              color="primary-textLow"
              fontSize="caption"
              lineHeight="caption"
            >
              {t('products.detail.videoBetaTag')}
            </Text>
          </Tag>
        )
      }
    >
      <Box display="flex" flexDirection="column" gap="4">
        <AlertMediaStatus isFormSubmitted={isFormSubmitted} />
        <AlertCloneStatus />
        <MediaDropArea
          isEdit={isEdit || false}
          videoQuantity={videoQuantity}
          onUploadImages={handleOnchange}
          onClick={handleOnClickAdd}
        />
        <MediaPreview
          onClickVideo={onClickVideo}
          onRemove={handleOnRemove}
          onEdit={handleOnEdit}
          openModal={handleOnOpenModal}
          onError={onRetryError}
          images={images}
        />
        {!!message && <UploadingMessage message={message} />}
        <RecommendationsGridRow />
        <Video
          videoUrl={videoUrl}
          errors={errors}
          onChange={onChangeExternalVideoUrl}
          isEdit={isEdit}
        />
        <Link appearance="primary" onClick={handleOnOpenModal}>
          <EditIcon />
          {hasUploadVideos
            ? t('products.detail.seeAllMedia')
            : t('products.detail.seeAllPhotos')}
        </Link>
      </Box>

      <ImageGalleryModal
        isEdit={isEdit || false}
        isShow={isShowModal}
        onClose={handleOnCloseModal}
        images={images}
        imageIdForEditOnOpen={imageIdForEditOnOpen}
        onOpenEditModal={onOpenEditModal}
        onChange={onChange}
        onError={onRetryError}
        isOpenFileSystem={isOpenFileSystem}
        onUploadImages={handleOnchange}
        onClickVideo={onClickVideo}
        message={message}
        altTextGenerationContext={altTextGenerationContext}
      />
    </CardWithTitle>
  );
}

export default ImageGallery;

import { Fragment, useCallback } from 'react';
import { Box, Button, Spinner, Text } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { useToastStatus } from 'commons/hooks';
import { getMainConceptFromCharges } from 'domains/Billing/Checkout/hooks/utils';
import { trackingBillingStartPayment } from 'domains/Billing/Checkout/tracking';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { ChargeToPay, NoCharges } from '../';
import { useSelectCharges } from '../../hooks';
import SelectChargesToPayTabSkeleton from '../../Skeleton';

function SelectChargesToPay() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.toPay',
  );
  const formatCurrency = useFormatCurrency();

  const {
    refreshChargesToPay,
    chargesToSelect,
    totalOfSelectedCharges,
    payOrderCreationStatus,
    onPay,
    isCreatingPayOrder,
    isLoading,
    isError,
    selectedCharges,
  } = useSelectCharges(true);

  const handleOnPay = () => {
    const mainConcept = getMainConceptFromCharges(selectedCharges);
    trackingBillingStartPayment(false, mainConcept);
    onPay();
  };

  useToastStatus({
    status: payOrderCreationStatus,
    error: t('payOrderCreationFailed'),
  });

  const retryLoad = useCallback(() => {
    refreshChargesToPay();
  }, [refreshChargesToPay]);

  if (isLoading) return <SelectChargesToPayTabSkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{ children: t('error.retry'), onClick: retryLoad }}
      />
    );

  if (!chargesToSelect?.length) {
    return <NoCharges />;
  }

  return (
    <Box display="flex" flexDirection="column">
      {chargesToSelect?.map((charge) => (
        <Fragment key={charge.id}>
          <ChargeToPay charge={charge} />
          <hr />
        </Fragment>
      ))}
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        padding="4"
        alignItems="center"
      >
        <Button
          disabled={isCreatingPayOrder || totalOfSelectedCharges <= 0}
          appearance="primary"
          onClick={handleOnPay}
        >
          {t('continueToPay')}
          {isCreatingPayOrder && <Spinner size="small" />}
        </Button>
        <Box display="flex" flexDirection="column">
          <Text fontSize="caption">{t('total')}</Text>
          <Text color="primary-textHigh" fontSize="highlight" fontWeight="bold">
            {formatCurrency(
              totalOfSelectedCharges,
              chargesToSelect[0].amountCurrency,
            )}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}

export default SelectChargesToPay;

import { Text, Tooltip, Icon, Chip } from '@nimbus-ds/components';
import { InfoCircleIcon } from '@nimbus-ds/icons';
import { Stack } from 'commons/components';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

function TaxRuleField({ hasTaxRule }) {
  const t = useTranslationCatalog('products.detail.invoice');
  //TODO:Mock data to be replaced by the service
  const taxRules = ['Regra general', 'Regra de impostos'];

  return (
    <Stack column align="flex-start" gap="1">
      <Stack gap="1">
        <Text fontSize="caption">{t('titleTaxRule')}</Text>
        <Tooltip content={t('tooltipTaxRule')} position="right">
          <Icon color="currentColor" source={<InfoCircleIcon size={12} />} />
        </Tooltip>
      </Stack>
      {hasTaxRule ? (
        <Stack spacing="tight" wrap>
          {taxRules.map((taxRule, index) => (
            <Chip key={index} text={taxRule} />
          ))}
        </Stack>
      ) : (
        <Text fontSize="caption">{t('NoTaxRule')}</Text>
      )}
    </Stack>
  );
}

export default TaxRuleField;

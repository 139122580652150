import { Locale } from 'App/i18n/enums';

export interface InterfaceApp {
  id: number;
  name: string;
  handle: string;
  description: string;
  image: string;
  profile: string;
  category: string;
}

export const apps = {
  [Locale.ARGENTINA]: [
    {
      id: 9667,
      name: 'ReadySize',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/9667-es_AR-DiseÃ±o sin tÃ­tulo.jpg',
      profile: 'https://www.tiendanube.com/tienda-aplicaciones-nube/readysize',
      category: 'extra resources',
      description:
        'Recomendador de talles con IA y ChatGPT. Reducí las devoluciones y aumentá la conversión de tu tienda.',
      handle: 'readysize',
    },
    {
      id: 11226,
      name: 'Shop the Look | Appsnube',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/11226-es_AR-logo_envoltorio (1).gif',
      profile:
        'https://www.tiendanube.com/tienda-aplicaciones-nube/shop-the-look',
      category: 'marketing',
      description:
        'Subí tus looks y vinculá los productos asociados para que sean más fáciles de encontrar y comprar (unitarios o en conjunto)',
      handle: 'shop-the-look',
    },
    {
      id: 964,
      name: 'Notificaciones de Stock',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/964-es_AR-small-logo_small.png',
      profile:
        'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/notificaciones-de-stock',
      category: 'marketing',
      description: 'Avisale a tus clientes cuando renueves el stock',
      handle: 'notificaciones-de-stock',
    },
    {
      id: 2910,
      name: 'CrossUp',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/2910-es_AR-small-GIF (CrossUp) 500x500.gif',
      profile:
        'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/crossup-flowy',
      category: 'marketing',
      description:
        'Ofrecé productos complementarios y mejoras en las compras de tus clientes',
      handle: 'crossup-flowy',
    },
    {
      id: 826,
      name: 'TITANPush',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/826-es_AR-small-ISOLOGO 2020.png',
      profile:
        'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/titanpush',
      category: 'marketing',
      description:
        'Impulsá tu e-commerce con herramientas que personalizan la experiencia de compra.',
      handle: 'titanpush',
    },
    {
      id: 2621,
      name: 'Estadísticas Nube',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/2621-es_AR-small-logo600x600.jpg',
      profile:
        'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/estadisticas-nube',
      category: 'statistics',
      description: 'Descubrí información clave para tu negocio',
      handle: 'estadisticas-nube',
    },
  ],
  [Locale.MEXICO]: [
    {
      id: 13154,
      name: 'Pinterest',
      description:
        'Muestra tus productos a los usuarios de Pinterest. Conecta tu Tiendanube, sube tu catálogo y sigue l...',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/13154-es_MX-P-Badge-Red-CMYK (2).jpg',
      profile:
        'https://www.tiendanube.com/mx/tienda-aplicaciones-nube/pinterest-app',
      category: 'marketing',
      handle: 'pinterest-app',
    },
    {
      id: 4280,
      name: 'Popup Sales',
      description:
        'Configure una ruleta en su tienda para aumentar suscriptores y ventas',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/4280-es_MX-4280-es_AR-small-Diseño sin título (3).png',
      profile:
        'https://www.tiendanube.com/mx/tienda-aplicaciones-nube/popup-sales',
      category: 'marketing',
      handle: 'popup-sales',
    },
    {
      id: 9746,
      name: 'Dropify',
      description:
        'Dropi facilita dropshipping y envíos en CO, CL, y MX, integrando transportadoras y gestionando pagos...',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/9746-es_AR-Screenshot 2024-08-28 171308.png',
      profile:
        'https://www.tiendanube.com/co/tienda-aplicaciones-nube/dropi-app',
      category: 'dropshipping',
      handle: 'dropi-app',
    },
    {
      id: 2097,
      name: 'Envia Paquetería y Fulfillment',
      description:
        'Plataforma de logística para envíos Nacionales e Internacional',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/2097-es_MX-small-envia.png',
      profile:
        'https://www.tiendanube.com/mx/tienda-aplicaciones-nube/envia-com',
      category: 'shipping',
      handle: 'envia-com',
    },
    {
      id: 13725,
      name: 'COD Checkout Form',
      description:
        'Acepta pagos contra entrega en tu tienda, ideales para dropshipping.',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/13725-es_AR-Dropshipping2.gif',
      profile:
        'https://www.tiendanube.com/co/tienda-aplicaciones-nube/cod-checkout-form',
      category: 'dropshipping',
      handle: 'cod-checkout-form',
    },
    {
      id: 5904,
      name: 'Revie: Reviews por Whatsapp',
      description: 'Obtén reseñas y testimoniales de clientes por Whatsapp.',
      image:
        'http://d2r9epyceweg5n.cloudfront.net/apps/5904-es_MX-revie_tn_logo.png',
      profile: 'https://www.tiendanube.com/mx/tienda-aplicaciones-nube/revie',
      category: 'security',
      handle: 'revie',
    },
  ],
  [Locale.COLOMBIA]: [
    {
      id: 11271,
      name: 'Bold',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/11271-es_AR-App_logo.png',
      profile: 'https://www.tiendanube.com/co/tienda-aplicaciones-nube/bold',
      category: 'payments',
      description:
        'Recibe pagos en tu tienda de forma segura con los métodos de pago más usados y con la mejor experiencia para tus clientes.',
      handle: 'bold',
    },
    {
      id: 7081,
      name: 'Addi - Compra ahora, paga después',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/7081-es_AR-2472-pt_BR-small-image (71).png',
      profile: 'https://www.tiendanube.com/co/tienda-aplicaciones-nube/addi-co',
      category: 'payments',
      description: 'Compra ahora, paga después',
      handle: 'addi-co',
    },
    {
      id: 9746,
      name: 'Dropify',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/9746-es_AR-Screenshot 2024-08-28 171308.png',
      profile:
        'https://www.tiendanube.com/co/tienda-aplicaciones-nube/dropi-app',
      category: 'dropshipping',
      description:
        'Dropi facilita dropshipping y envíos en CO, CL, y MX, integrando transportadoras y gestionando pagos a través de su wallet.',
      handle: 'dropi-app',
    },
    {
      id: 11859,
      name: 'Siigo',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/11859-es_AR-logo_siigo.png',
      profile:
        'https://www.tiendanube.com/co/tienda-aplicaciones-nube/siigo-erp-integracion',
      category: 'management',
      description: 'Siigo - Integración',
      handle: 'siigo-erp-integracion',
    },
    {
      id: 2097,
      name: 'Envia Paquetería y Fulfillment',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/2097-es_AR-276166428_5086905508037302_7387965448349951746_n.jpg',
      profile:
        'https://www.tiendanube.com/co/tienda-aplicaciones-nube/envia-com',
      category: 'shipping',
      description:
        'Plataforma de logística para envíos Nacionales e Internacional',
      handle: 'envia-com',
    },
    {
      id: 13725,
      name: 'COD Checkout Form | Appsnube',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/13725-es_AR-Dropshipping2 (1).gif',
      profile:
        'https://www.tiendanube.com/co/tienda-aplicaciones-nube/cod-checkout-form',
      category: 'dropshipping',
      description:
        'Simplifica las compras contra entrega en un solo clic, eliminando el proceso tradicional de checkout. ',
      handle: 'cod-checkout-form',
    },
  ],
  [Locale.CHILE]: [
    {
      id: 11178,
      name: 'WebPay-Transbank',
      description: 'Agrega webpay transbank como medio de pago.',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/11178-es_AR-transbank.png',
      profile:
        'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/webpaytransbank',
      category: 'payments',
      handle: 'webpaytransbank',
    },
    {
      id: 10425,
      name: 'FLOW',
      description:
        'Plataforma de pagos, permite recibir pagos con tarjetas bancarias, en cuotas sin interés, billeteras...',
      image: 'https://d2r9epyceweg5n.cloudfront.net/apps/10425-es_AR-flow.jpg',
      profile: 'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/flow',
      category: 'payments',
      handle: 'flow',
    },
    {
      id: 3623,
      name: 'Wibond',
      description:
        'Ofrece a tus compradores pagar en cuotas sin tarjeta de crédito. Pueden comprar en 4 cuotas sin inte...',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/3623-es_AR-600x600.png',
      profile: 'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/wibond',
      category: 'payments',
      handle: 'wibond',
    },
    {
      id: 13283,
      name: 'Enviame',
      description:
        'Conecta los mejores couriers para tus envíos nacionales en un solo lugar.',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/13283-es_AR-Logo 600x600 Tienda Nuve.jpg',
      profile:
        'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/enviame-tiendanube',
      category: 'shipping',
      handle: 'enviame-tiendanube',
    },
    {
      id: 9746,
      name: 'Dropify',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/9746-es_AR-Screenshot 2024-08-28 171308.png',
      profile:
        'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/dropi-app',
      category: 'dropshipping',
      description:
        'Dropi facilita dropshipping y envíos en CO, CL, y MX, integrando transportadoras y gestionando pagos a través de su wallet.',
      handle: 'dropi-app',
    },
    {
      id: 915,
      name: 'TFactura',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/915-es_AR-logo TFactura 600x600-02.png',
      profile:
        'https://www.tiendanube.com/cl/tienda-aplicaciones-nube/tango-factura',
      description:
        'Factura, sincroniza stock y precios, gestiona, y ahorra tiempo! ',
      category: 'management',
      handle: 'tango-factura',
    },
  ],
  [Locale.BRAZIL]: [
    {
      id: 13154,
      name: 'Pinterest',
      description:
        'Mostre seus produtos para quem usa o Pinterest. Conecte sua loja da Nuvemshop, carregue seu catálogo...',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/13154-es_MX-P-Badge-Red-CMYK Medium res comp.jpg',
      profile:
        'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/pinterest-app',
      category: 'marketing',
      handle: 'pinterest-app',
    },
    {
      id: 6208,
      name: 'Cashing',
      description:
        'Desconto progressivo, brinde no carrinho, compre e ganhe, upsell, compre junto, vitrine no carrinho,...',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/6208-es_AR-6208-pt_BR-cashing_azul.png',
      profile: 'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/cashing',
      category: 'marketing',
      handle: 'cashing',
    },
    {
      id: 4092,
      name: 'Pagaleve',
      description:
        'A Pagaleve, empresa líder em Pix Parcelado, oferece o método de pagamento de parcelamento via Pix pa...',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/4092-pt_BR-small-Logo-Tela-de-Consentimento (2).jpeg',
      profile:
        'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/pagaleve-pix-parcelado',
      category: 'payments',
      handle: 'pagaleve-pix-parcelado',
    },
    {
      id: 4940,
      name: 'Troquecommerce',
      description:
        'Automatize suas trocas e devoluções no seu site. Emissão de reversa via Correios ou Transportadoras...',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/4940-pt_BR-small-troquecommerce_logo.png',
      profile:
        'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/troquecommerce-br',
      category: 'shipping',
      handle: 'troquecommerce-br',
    },
    {
      id: 5378,
      name: 'Aliexpress By Droplinkfy',
      description:
        'Automação para dropshipping que conecta a loja com ao Aliexpress e disponibiliza ferramentas para a...',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/5378-pt_BR-ali by drop gif.gif',
      profile:
        'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/aliexpress-by-droplinkfy',
      category: 'dropshipping',
      handle: 'aliexpress-by-droplinkfy',
    },
    {
      id: 35,
      name: 'Bling',
      description: 'ERP para e-commerce. Teste grátis por 4 meses',
      image:
        'https://d2r9epyceweg5n.cloudfront.net/apps/35-pt_BR-small-Logo-Bling-400x400px-com-fundo.png',
      profile: 'https://www.nuvemshop.com.br/loja-aplicativos-nuvem/bling',
      category: 'management',
      handle: 'bling',
    },
  ],
};

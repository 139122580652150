import { Box, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, DataList, InterfaceLabel, Label } from '@tiendanube/components';

interface CustomerPriceTablesSkeletonProps {
  headerLabel: InterfaceLabel;
}

export default function CustomerPriceTablesSkeleton({
  headerLabel,
}: Readonly<CustomerPriceTablesSkeletonProps>) {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const tPath = 'customerDetails.customerPriceTables';

  return (
    <Card title={t(`${tPath}.title`)} headerLabel={headerLabel}>
      <DataList>
        <DataList.Row id="skeleton">
          <DataList.Cell width={100} rowTitle>
            <Box marginBottom="0-5">
              <Text.Skeleton width="100px" />
            </Box>
          </DataList.Cell>
          <DataList.Cell width={100}>
            <Text.Skeleton width="130px" />
          </DataList.Cell>
          <DataList.Cell width={100}>
            <Box display="flex" gap="2" marginTop="0-5">
              <Label.Skeleton />
              <Label.Skeleton />
            </Box>
          </DataList.Cell>
        </DataList.Row>
      </DataList>
    </Card>
  );
}

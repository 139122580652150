import { Box, Icon, Link, Text, Title } from '@nimbus-ds/components';
import { ExternalLinkIcon } from '@nimbus-ds/icons';
import { Locale } from 'App/i18n/enums';
import { AppItemCard } from 'commons/components';
import goToAdmin from 'commons/utils/gotToAdmin';
import {
  useAppStoreTracker,
  useTranslationPartnerApps,
} from 'domains/PartnersApps/hooks';
import { apps as countriesApps, InterfaceApp } from './apps';

function EmptyAppListPage() {
  const { t, language } = useTranslationPartnerApps('appsListPage');
  const { clickToAppStoreAppInstall, clickToAppStoreAppDetails } =
    useAppStoreTracker();

  const apps =
    (countriesApps[language] as InterfaceApp[]) ?? countriesApps[Locale.BRAZIL];

  const handleClickInstall = (app: InterfaceApp) => {
    clickToAppStoreAppInstall('admin/v2/apps', app);
    goToAdmin(`/apps/${app.id}/authorize`)();
  };

  const handleOnClickTitle = (app: InterfaceApp) => {
    clickToAppStoreAppDetails('admin/v2/apps', app);
  };

  return (
    <Box display="flex" flexDirection="column" gap="8">
      <Box>
        <Title>{t('empty.title')}</Title>
        <Text>{t('empty.description')}</Text>
      </Box>
      <Link
        as="a"
        appearance="primary"
        textDecoration="none"
        href={t('empty.appStoreUrl')}
        target="_blank"
      >
        {t('empty.goToApp')}
        <Icon color="primary-interactive" source={<ExternalLinkIcon />} />
      </Link>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: '1fr',
          md: '1fr 1fr',
        }}
        gap="6"
      >
        {Object.keys(apps)?.map((key, i) => {
          const app = apps[key] as InterfaceApp;
          return (
            <AppItemCard
              key={`${app.name}${i}`}
              onClickInstall={() => handleClickInstall(app)}
              onClickTitle={() => handleOnClickTitle(app)}
              app={{
                name: app.name,
                handle: app.handle,
                description: app.description,
                image: app.image,
                profileUrl: app.profile,
              }}
            />
          );
        })}
      </Box>
    </Box>
  );
}

export default EmptyAppListPage;

import { useCallback } from 'react';
import { Box, Tag, Text } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { CheckoutPaymentMethod, RecurrencyOptionDto } from '@tiendanube/common';
import { useToastStatus } from 'commons/hooks';
import {
  useFrequencyTranslation,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';
import { trackingBillingSelectRecurrencyClick } from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useContinuePayment } from '../../../../hooks';

interface RecurrencyOptionProps {
  option: RecurrencyOptionDto;
  method: CheckoutPaymentMethod;
  isForPlanOnly: boolean;
}

export default function RecurrencyOption({
  method,
  option,
  isForPlanOnly,
}: Readonly<RecurrencyOptionProps>) {
  const t = useTranslationBilling(
    'checkout.choosePaymentMethodWithRecurrency.chooseRecurrency',
  );
  const { getFrequencyDescriptor } = useFrequencyTranslation();

  const { mainConcept } = usePayorderToPay();

  const discountPercentage = option.appliedDiscounts.reduce(
    (acc, discount) => acc + discount.rate,
    0,
  );
  const { onPay, status: continueStatus } = useContinuePayment();

  const monthRecurrency =
    option.recurringFrequency === 'M'
      ? option.recurringInterval
      : Math.round(option.recurringInterval / 30);

  // Option is disabled if we're paying something other than a plan and the recurrency is not monthly
  const enabled = isForPlanOnly || monthRecurrency === 1;

  useToastStatus({
    status: continueStatus,
    error: t('continueError'),
    progress: t('continueLoading'),
  });

  const onClick = useCallback(() => {
    if (enabled) {
      trackingBillingSelectRecurrencyClick(
        option.recurringInterval,
        option.recurringFrequency,
        mainConcept,
      );
      onPay(method, option);
    }
  }, [onPay, enabled, method, option, mainConcept]);

  return (
    <Box
      padding="4"
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={enabled ? 'transparent' : 'neutral-surface'}
      onClick={onClick}
    >
      <Box display="flex" flexDirection="column" gap="3">
        <Text>{`${t('subscription')} ${getFrequencyDescriptor(option)}`}</Text>
        {discountPercentage > 0 && (
          <Tag appearance={enabled ? 'success' : 'neutral'}>
            {t('discountLabel', {
              percentage: discountPercentage,
            })}
          </Tag>
        )}
      </Box>
      {enabled && <ChevronRightIcon />}
    </Box>
  );
}

import { Box, Card, Link, Tag, Text, Title } from '@nimbus-ds/components';
import { ConceptCode } from '@tiendanube/common';
import { ErrorState } from '@tiendanube/components';
import { useIsInAppBrowser } from 'App/hooks';
import { useGoToPlans } from 'commons/hooks/useGoToPlans';
import { dateFormat, Format } from 'commons/utils/date';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import {
  useExpirationDate,
  useFrequencyTranslation,
} from 'domains/Billing/Checkout/hooks';
import { trackingBillingChangePlansClick } from 'domains/Billing/Checkout/tracking';
import { useFormatCurrency } from 'domains/Billing/hooks';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { useSubscriptionDetail } from './hooks';
import SubscriptionDetailSkeleton from './Skeleton';

interface SubscriptionDetailProps {
  concept: ConceptCode;
  externalReference?: string;
}

function SubscriptionDetail({
  concept,
  externalReference,
}: SubscriptionDetailProps) {
  const t = useTranslationBilling(
    'checkout.conceptDetailContent.subscriptionDetail',
  );
  const { handleGoToPlans: goToPlans } = useGoToPlans();
  const handleGoToPlans = () => {
    trackingBillingChangePlansClick();
    goToPlans();
  };
  const { expirationDate } = useExpirationDate(concept, externalReference);

  const formatCurrency = useFormatCurrency();

  const { getFrequencyInterval } = useFrequencyTranslation();

  const {
    isLoading,
    isError,
    retryLoad,
    isAvailableForPayment,
    subscription,
    recurrentPaymentActivated,
  } = useSubscriptionDetail(concept, externalReference);
  const isMobileDevice = useIsMobileDevice();
  const isInAppBrowserOpen = useIsInAppBrowser();
  const isWebVersion = !isMobileDevice && !isInAppBrowserOpen;

  if (isLoading) return <SubscriptionDetailSkeleton />;

  if (!subscription) return null;

  return (
    <Card>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Title as="h3">{t('title')}</Title>
        {recurrentPaymentActivated && (
          <Tag appearance="neutral">{t('recurrentPaymentTag')}</Tag>
        )}
      </Box>
      {isError ? (
        <ErrorState
          title={t('error.title')}
          action={{ onClick: retryLoad, children: t('error.retry') }}
        />
      ) : (
        <Box display="flex" flexDirection="column" gap="2-5">
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text>{subscription.description}</Text>
            {isWebVersion && subscription.isPlan && (
              <Link
                textDecoration="none"
                appearance="primary"
                onClick={handleGoToPlans}
              >
                {t('changePlan')}
              </Link>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text>{`${t('cost')}`}</Text>
            <Box display="inline-flex">
              <Text>
                {formatCurrency(
                  subscription.amountValue,
                  subscription.amountCurrency,
                )}
              </Text>
              <Text
                color="neutral-textDisabled"
                fontSize="caption"
              >{`/${getFrequencyInterval(subscription)}`}</Text>
            </Box>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text>{`${t('nextExecutionDate')}`}</Text>
            <Text>
              {dateFormat(
                subscription.nextExecutionDate,
                Format.DD_MM_YYYY,
                'UTC',
              )}
            </Text>
          </Box>
          {!!expirationDate &&
            isAvailableForPayment(concept, externalReference) && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Text>{`${t('expirationDate')}`}</Text>
                <Text>
                  {dateFormat(
                    expirationDate.toISOString(),
                    Format.DD_MM_YYYY,
                    'UTC',
                  )}
                </Text>
              </Box>
            )}
        </Box>
      )}
    </Card>
  );
}

export default SubscriptionDetail;

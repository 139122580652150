import { useEffect } from 'react';
import { ChoosePaymentMethod } from './components';
import { usePayorderToPay } from '../../hooks';
import { trackingBillingViewSelectPaymentMethodForPlan } from '../../tracking';
import { ChoosePaymentMethodWithRecurrencyLayout } from '../components';

export default function ChoosePaymentMethodPage() {
  const { mainConcept } = usePayorderToPay();
  useEffect(() => {
    trackingBillingViewSelectPaymentMethodForPlan(mainConcept);
  }, [mainConcept]);

  return (
    <ChoosePaymentMethodWithRecurrencyLayout
      mainContent={<ChoosePaymentMethod />}
    />
  );
}

import { MediaGalleryState } from '../../../ImageGallery';

interface UsePreviewItemsResult {
  previewItems: MediaGalleryState[];
  moreItemsNumber: number;
  isShowMoreItems: boolean;
  maxPreviewItems: number;
}

interface UsePreviewItemsProps {
  numberOfImagesToPreview: number;
  images: MediaGalleryState[];
}

function usePreviewItems({
  numberOfImagesToPreview,
  images,
}: UsePreviewItemsProps): UsePreviewItemsResult {
  const totalImages = images.length;
  const maxPreviewItems =
    totalImages > numberOfImagesToPreview
      ? numberOfImagesToPreview - 1
      : numberOfImagesToPreview;
  const previewItems = images.slice(0, maxPreviewItems);
  const moreItemsNumber = totalImages - (maxPreviewItems + 1);
  const isShowMoreItems = totalImages > maxPreviewItems;

  return {
    previewItems,
    moreItemsNumber,
    isShowMoreItems,
    maxPreviewItems,
  };
}

export default usePreviewItems;

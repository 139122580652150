/* eslint-disable max-statements */
import { useCallback, useEffect, useState } from 'react';
import { ProductSectionsCodeType } from '@tiendanube/common';
import { InterfaceNameChecked } from '@tiendanube/components';
import {
  NEW_ADMIN_EVENT_EDIT_VARIANT_STOCK,
  CATALOG_ENABLE_INF_STOCK_MODAL,
  CATALOG_LIGHT_VIDEO_URL_VALIDATION,
  CATALOG_FULL_EVENTS,
} from 'App/featuresFlags';
import useYupValidation from 'commons/hooks/useYupValidation';
import { scrollToFirstBlockSubmitError } from 'commons/utils';
import {
  useGetAllLanguages,
  useGetLanguage,
  useGetTags,
} from 'domains/Auth/hooks';
import { trackingEditNewVariantStock } from 'domains/Catalog/Products/tracking';
import { OptionEditType } from 'domains/Catalog/Products/utils';
import { SourceType } from 'domains/Metafields/types';
import {
  UseProductFormProps,
  UseProductFormResult,
  ProductFormState,
  HandleChangeInterface,
  RelatedProductInterface,
} from './types';
import { buildProductSchema } from './useProductFormUtils';
import { ImageGalleryState } from '../../components/ImageGallery/ImageGallery';
import {
  Variant,
  Attributes,
  MetafieldSelectedInterface,
  MetafieldSelectedsOrErrorType,
} from '../../components/Variants/types';
import {
  createVariantsStructure,
  hasTheSameValues,
  getDifferences,
  buildNewVariant,
  getCurrentVariants,
  getVariantEdited,
  getUpdatedStockValue,
  getIsFormDirty,
} from '../../NewProductPage/productFormUtils';
import useVariantsStock from '../useVariantsStock';

type NullVaritantStockModalState = 'hide' | 'show' | 'cancel' | 'confirm';

function useProductForm({
  initialValues,
  onSave,
  onError,
  onSuccess,
}: UseProductFormProps): UseProductFormResult {
  const language = useGetLanguage();
  const languages = useGetAllLanguages();
  const variantsStock = useVariantsStock();
  const tags = useGetTags();
  const enabledNullVaritantStock = tags.includes(
    CATALOG_ENABLE_INF_STOCK_MODAL,
  );

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [nullVaritantStockModalState, setNullVaritantStockModalState] =
    useState<NullVaritantStockModalState>('hide');

  const fullValidateUrlVideo = !tags.includes(
    CATALOG_LIGHT_VIDEO_URL_VALIDATION,
  );
  const validationSchema = buildProductSchema(languages, fullValidateUrlVideo);
  const { errors, validate } = useYupValidation<
    ProductFormState,
    Record<string, string>
  >(validationSchema);

  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [values, setValues] = useState<ProductFormState>(initialValues);

  useEffect(() => {
    setIsDirty(getIsFormDirty(initialValues, values));
  }, [initialValues, values]);

  useEffect(() => {
    // update variant stock from values when varianstStock is updated
    setValues((values) =>
      values.variants.length === 1
        ? {
            ...values,
            variants: values.variants.map((variant) => ({
              ...variant,
              stock: getUpdatedStockValue(variantsStock, variant),
            })),
          }
        : values,
    );
  }, [variantsStock]);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);

  const handleSave = async (
    e?: React.SyntheticEvent<HTMLFormElement | HTMLButtonElement>,
  ) => {
    e?.preventDefault();
    setIsFormSubmitted(true);
    if (scrollToFirstBlockSubmitError()) {
      return;
    }
    validate(values, async (validatedData) => {
      if (
        enabledNullVaritantStock &&
        nullVaritantStockModalState === 'hide' &&
        values.variants.some((v) => v.id === '' && v.stock === null)
      ) {
        setNullVaritantStockModalState('show');
        return;
      }
      setIsDirty(false);
      setIsSaving(true);
      try {
        setIsDirty(false);
        const differences = getDifferences<ProductFormState>(
          initialValues,
          validatedData,
        );
        await onSave(differences);
        if (
          (tags.includes(NEW_ADMIN_EVENT_EDIT_VARIANT_STOCK) ||
            tags.includes(CATALOG_FULL_EVENTS)) &&
          differences.variants !== undefined
        ) {
          trackingEditNewVariantStock(differences);
        }
        onSuccess();
      } catch (error) {
        setIsDirty(getIsFormDirty(initialValues, values));
        onError();
      } finally {
        setIsFormSubmitted(false);
        setIsSaving(false);
        setNullVaritantStockModalState('hide');
      }
    });
  };

  const handleChange = ({ name, value }: HandleChangeInterface) => {
    setValues((values) => ({ ...values, [name]: value }));
  };

  const handleChangeVariants = useCallback(
    (index: number, data: Partial<Variant>) => {
      setValues((values) => ({
        ...values,
        variants: getVariantEdited(values.variants, index, data),
      }));
    },
    [],
  );

  // update price require to have pricePusblished set on true
  const handleChangeToAllVariants =
    (
      name: string,
      value: string | number | boolean | null,
      stockParams?: {
        type: OptionEditType;
        currentVariantId: string;
      },
    ) =>
    () => {
      setValues((values) => ({
        ...values,
        variants: values.variants.map((variant) => {
          const stock = variant.stock ? Number(variant.stock) : 0;
          const newValue = value ? value : stock;
          const operations = {
            add: stock + Number(value),
            discount: stock - Number(value),
            replace: newValue,
          };
          const newStock = (
            stockParams?.type ? operations[stockParams?.type] : newValue
          ).toString();

          const changedObject = {
            ...variant,
            pricePublished: name === 'price' ? true : variant.pricePublished,
            [name]: value,
          };

          if (name === 'stock' && stockParams) {
            changedObject[name] =
              stockParams?.currentVariantId !== variant.id
                ? newStock
                : stock.toString();
          }

          return changedObject;
        }),
      }));
    };

  const handleChangeToAllMetafieldsVariants = (
    selectedMetafield: MetafieldSelectedInterface,
    source: SourceType,
  ) => {
    setValues((values) => {
      const newValues = {
        ...values,
        variants: values.variants.map((variant) => {
          const initialValue = {
            internals: variant.metafields?.internals || [],
            fromApi: variant.metafields?.fromApi || [],
          };
          const isAdmin = source === SourceType.ADMIN;

          const attr = isAdmin ? 'internals' : 'fromApi';
          const metafields = variant.metafields;

          const variantMetafields = metafields
            ? metafields[attr] !== 'error'
              ? metafields[attr]
              : []
            : [];

          const rest = (
            variantMetafields as MetafieldSelectedInterface[]
          ).filter((current) => current.id !== selectedMetafield.id);

          const isValueEmpty = selectedMetafield.value === '';

          initialValue[attr] = [
            ...rest,
            {
              id: selectedMetafield.id,
              value: !isValueEmpty ? selectedMetafield.value : null,
            },
          ] as MetafieldSelectedsOrErrorType;

          return {
            ...variant,
            metafields: initialValue,
          };
        }),
      };
      return newValues;
    });
  };

  const handleChangeCategories = (categories: string[]) => {
    setValues((values) => ({ ...values, categories }));
  };

  const handleChangeSectionCodes = (event: InterfaceNameChecked) => {
    if (event.checked) {
      setValues((values) => ({
        ...values,
        sectionCodes: [
          ...values.sectionCodes,
          event.name as ProductSectionsCodeType,
        ],
      }));
    } else {
      setValues((values) => ({
        ...values,
        sectionCodes: values.sectionCodes.filter(
          (section) => section !== event.name,
        ),
      }));
    }
  };

  const handleChangeRelatedProducts = (
    relatedProducts: RelatedProductInterface,
  ) => {
    setValues((values) => ({
      ...values,
      relatedProducts,
    }));
  };

  const handleChangeImages = (
    imagesOrMedia: ImageGalleryState[],
    id?: string,
  ) => {
    // when id is defined, it means that bff has returned the uploaded image state and we need to update the local state
    // multiple image's upload are async, so we need to get the current "values.images" state by the setValues func to edit and update it
    if (!id) {
      // If the first element is a video and there is more than one item in the array,
      // move the video to the end to ensure it never stays in the first position.
      if (imagesOrMedia[0]?.mediaType === 'video' && imagesOrMedia.length > 1) {
        const [video, ...rest] = imagesOrMedia;
        imagesOrMedia = [...rest, video];
      }
      setValues((values) => ({
        ...values,
        images: imagesOrMedia.filter(({ mediaType }) => mediaType === 'image'), // POST-ROLLOUT: remove when the sunset of new-admin-catalog-upload-videos is done
        media: imagesOrMedia,
      }));
    } else {
      setValues((values) => ({
        ...values,
        images: [
          // POST-ROLLOUT: remove when the sunset of new-admin-catalog-upload-videos is done
          ...values.images.map((image) =>
            image.id === id ? { ...image, ...imagesOrMedia[0] } : image,
          ),
        ],
        media: [
          ...values.media.map((currentMedia) =>
            currentMedia.id === id
              ? { ...currentMedia, ...imagesOrMedia[0] }
              : currentMedia,
          ),
        ],
      }));
    }
  };

  const handleChangeAttributes = (attributes: Attributes) => {
    setValues((values) => ({
      ...values,
      attributes,
    }));

    const currentVariants = getCurrentVariants(values.variants);

    const newVariantsKeys = createVariantsStructure(
      attributes,
      language,
      languages,
    );

    const needUpdate = !hasTheSameValues(currentVariants, newVariantsKeys);

    if (needUpdate) {
      const newVariants = buildNewVariant(
        newVariantsKeys,
        values.variants,
        language,
      );

      setValues((values) => ({
        ...values,
        variants: newVariants,
      }));
    }
  };

  const handleCloseModalNullStockVariant = () => {
    setNullVaritantStockModalState('cancel');
  };

  const handleConfirmModalNullVaritantStock = () => {
    setNullVaritantStockModalState('confirm');
  };

  useEffect(() => {
    if (nullVaritantStockModalState === 'confirm') {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nullVaritantStockModalState]);

  return {
    values,
    isDirty,
    isSaving,
    isFormSubmitted,
    showModalNullStockVariant: nullVaritantStockModalState === 'show',
    handleCloseModalNullStockVariant,
    handleConfirmModalNullVaritantStock,
    handleChangeImages,
    handleSave,
    handleChange,
    handleChangeCategories,
    handleChangeVariants,
    handleChangeAttributes,
    handleChangeToAllVariants,
    handleChangeSectionCodes,
    handleChangeToAllMetafieldsVariants,
    handleChangeRelatedProducts,
    errors,
  };
}

export default useProductForm;

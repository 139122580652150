/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { PickupPointRequestDto } from '@tiendanube/common';
import { useToastProgress } from 'App/components/ToastProgressContext';
import { useNavegate } from 'App/hooks';
import { useAsyncFunc, useForm, useToast } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import { useLocationDetails } from 'domains/Shipping/Locations/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import { COUNTRIES_WITH_GEOLOCATION_INFO } from 'domains/Shipping/utils';
import { PickupForm } from '../../components';
import { validationSchemaSecondStep } from '../../components/PickupForm/validationSchemaSecondStep';
import usePickupPoints from '../../hooks/usePickupPoints';
import { initialValues } from '../utils';

function AddLocationAsPickupPage(): JSX.Element {
  const country = useGetCountry();
  const t = useTranslationShipping(
    'deliveryMethods.pickupPoints.locations.toast',
  );
  const { location: locationId } = useParams<{ location: string }>();
  const { createPickupPoint } = usePickupPoints();
  const { fetchLocation, location, isError, isLoading } =
    useLocationDetails(locationId);
  const { goBack } = useNavegate();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const initialValuesForLocation = useMemo<PickupPointRequestDto>(
    () =>
      location
        ? {
            ...initialValues,
            isLocation: true,
            name: location.locationName,
            locations: [location.id],
          }
        : {
            ...initialValues,
            range: COUNTRIES_WITH_GEOLOCATION_INFO.has(country)
              ? initialValues.range
              : '',
          },
    [location],
  );

  const [handleCreatePickupPoint, isSaving] = useAsyncFunc<
    PickupPointRequestDto,
    Promise<void>
  >(
    async (data) => {
      if (data) {
        addToastProgress({
          label: t('create.loading'),
        });
        await createPickupPoint(data);
      }
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('create.success'),
        appearance: 'success',
      });
      goBack();
    },
    () => {
      closeToastProgress();
      addToast({
        label: t('create.error'),
        appearance: 'danger',
      });
    },
  );

  const onRetryError = () => {
    fetchLocation();
  };

  useEffect(() => {
    if (!isLoading && !isError && locationId) {
      fetchLocation();
    }
  }, []);

  const {
    errors,
    values,
    isDirty,
    handleChange,
    handleOnSubmit,
    setFieldValue,
  } = useForm<PickupPointRequestDto>({
    initialValues: initialValuesForLocation,
    validationSchema: validationSchemaSecondStep,
    onSubmit: handleCreatePickupPoint,
  });

  return (
    <PickupForm
      isNew
      step={2}
      type="location"
      isSaving={isSaving}
      errors={errors}
      values={values}
      isDirty={isDirty}
      isError={isError}
      handleSave={handleOnSubmit}
      handleChange={handleChange}
      setFieldValue={setFieldValue}
      onRetryError={onRetryError}
    />
  );
}

export default AddLocationAsPickupPage;

import { Box } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Thumbnail } from '@tiendanube/components';
import { useResponsive } from 'commons/components';
import { useGetLanguage } from 'domains/Auth/hooks';
import MediaPreviewItem from './MediaPreviewItem';
import usePreviewItems from './usePreviewItems';
import { MediaGalleryState } from '../../ImageGallery';

export interface MediaPreviewProps {
  images: MediaGalleryState[];
  onClickVideo: () => void;
  openModal: () => void;
  onError: () => void;
  onRemove: (imageId: string) => void;
  onEdit: (imageId: string) => void;
}

function MediaPreview({
  images,
  onClickVideo,
  onRemove,
  onEdit,
  openModal,
  onError,
}: Readonly<MediaPreviewProps>): JSX.Element | null {
  const language = useGetLanguage();
  const { t } = useTranslation(Domain.CATALOG);

  const { isDesktop } = useResponsive();
  const maxImages = isDesktop ? 4 : 3;
  const aspectRatio = isDesktop ? '16-9' : '4-3';

  const { isShowMoreItems, previewItems, moreItemsNumber, maxPreviewItems } =
    usePreviewItems({
      numberOfImagesToPreview: maxImages,
      images,
    });

  const textPlus = t('products.detail.morePhotos', {
    moreItemsNumber,
  });

  if (!images.length) {
    return null;
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns={`repeat(${maxImages}, 1fr)`}
      gridGap="4"
    >
      {previewItems.map((image, index) => {
        const showMainTag = index === 0 && image.mediaType === 'image';
        return (
          <MediaPreviewItem
            key={image.id}
            image={image}
            alt={image?.alt?.[language] ?? ''}
            src={image.src}
            showMainTag={showMainTag}
            aspectRatio={aspectRatio}
            onEdit={onEdit}
            onError={onError}
            onRemove={onRemove}
            onClickVideo={onClickVideo}
          />
        );
      })}
      {isShowMoreItems && (
        <Thumbnail.Plus
          src={images[maxPreviewItems]?.src}
          altText={textPlus}
          ariaLabel={textPlus}
          number={moreItemsNumber}
          onClick={openModal}
          aspectRatio={aspectRatio}
        />
      )}
    </Box>
  );
}

export default MediaPreview;

import { useState } from 'react';
import { Card, Chip, Icon, Link, Radio, Text } from '@nimbus-ds/components';
import { PlusCircleIcon } from '@nimbus-ds/icons';
import { CustomShippingRangeDetailsDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import { useModal } from 'commons/hooks';
import { useGetCountry } from 'domains/Auth/hooks';
import useTranslationShipping from 'domains/Shipping/useTranslationShipping';
import Skeleton from './Skeleton';
import { getRangeTypeOptions, RangeType } from './utils';
import { SelectedZonesInterface } from '../../NewCustomShippingPage/components/DeliveryZoneCard';
import SelectProvinceModal from '../../NewCustomShippingPage/components/SelectModals/SelectProvinceModal';
import ShippingCustomRangeModal from '../../NewCustomShippingPage/components/ShippingCustomRangeModal';
import CustomShippingRangeList from '../CustomShippingRangeList';

interface CustomShippingRangeOptionsCardProps {
  rangeType: RangeType;
  ranges: CustomShippingRangeDetailsDto[];
  selectedZones: SelectedZonesInterface[];
  setFieldValue: (name: string, value: any) => void;
}

function CustomShippingRangeOptionsCard({
  rangeType,
  ranges,
  selectedZones,
  setFieldValue,
}: Readonly<CustomShippingRangeOptionsCardProps>): JSX.Element {
  const t = useTranslationShipping(
    'deliveryMethods.customShipping.rangeOptionsCard',
  );

  const country = useGetCountry();
  const rangeTypeOptions = getRangeTypeOptions(country);

  const [selectedRange, setSelectedRange] =
    useState<CustomShippingRangeDetailsDto>();

  const handleEditRange = (range: CustomShippingRangeDetailsDto) => {
    setSelectedRange(range);
    openRangesModal();
  };

  const handleDeleteRange = (range: CustomShippingRangeDetailsDto) => {
    setFieldValue(
      'ranges',
      ranges.filter((r) => r.initialRange !== range.initialRange),
    );
  };

  const onChangeRangeType = (value: RangeType) => {
    if (value === 'ranges' && ranges.length === 0) {
      openRangesModal();
    }

    if (value === 'zones' && selectedZones.length === 0) {
      openZonesModal();
    }

    setFieldValue('rangeType', value);
  };

  const onChangeZones = (deliveryZones: SelectedZonesInterface[]) => {
    setFieldValue('deliveryZones', deliveryZones);
  };

  const onChangeRanges = (ranges: CustomShippingRangeDetailsDto[]) => {
    setFieldValue('ranges', ranges);
    setSelectedRange(undefined);
  };

  const [isOpenZonesModal, openZonesModal, closeZonesModal] = useModal();
  const [isOpenRangesModal, openRangesModal, closeRangesModal] = useModal();

  const handleRemoveZone = (provinceId: string) => {
    if (!!selectedZones && selectedZones?.length > 0) {
      const newDeliveryZones = selectedZones.filter(
        (zone) => zone.provinceId !== provinceId,
      );
      onChangeZones(newDeliveryZones);
    }
  };

  return (
    <Card>
      <Card.Header title={t('title')} />
      <Card.Body>
        <Stack column align="flex-start" spacing="base">
          {rangeTypeOptions.map((option) => (
            <Radio
              key={option.value}
              id={option.value}
              label={t(option.label)}
              name="rangeType"
              value={option.value}
              onChange={() => onChangeRangeType(option.value)}
              as="radio"
              checked={rangeType === option.value}
            />
          ))}
        </Stack>
      </Card.Body>
      <Card.Footer>
        {rangeType === 'zones' && (
          <Card>
            <Stack align="flex-start" column spacing="tight">
              <Text fontSize="highlight" color="currentColor">
                {t('byZones.description')}
              </Text>
              <Stack wrap>
                {selectedZones?.map(
                  ({ provinceId, provinceName, citiesIds }) => (
                    <Chip
                      key={provinceId}
                      id={provinceId}
                      text={
                        citiesIds?.length !== 0
                          ? `${provinceName} (${citiesIds.length})`
                          : `${provinceName} (${t('byZones.allCities')})`
                      }
                      removable
                      onClick={() => handleRemoveZone(provinceId)}
                    />
                  ),
                )}
              </Stack>
              <Link
                appearance="primary"
                onClick={openZonesModal}
                textDecoration="none"
              >
                <Icon color="currentColor" source={<PlusCircleIcon />} />
                {t('byZones.action')}
              </Link>
            </Stack>
          </Card>
        )}
        {rangeType === 'ranges' && (
          <Stack column align="flex-start" spacing="base">
            {ranges.length > 0 && (
              <CustomShippingRangeList
                ranges={ranges}
                handleEdit={handleEditRange}
                handleDelete={handleDeleteRange}
              />
            )}
            <Link
              appearance="primary"
              onClick={openRangesModal}
              textDecoration="none"
            >
              <Icon color="currentColor" source={<PlusCircleIcon />} />
              {t('byRanges.action')}
            </Link>
          </Stack>
        )}
      </Card.Footer>
      {isOpenZonesModal && (
        <SelectProvinceModal
          selectedZones={selectedZones}
          isOpen={isOpenZonesModal}
          onClose={closeZonesModal}
          onChange={onChangeZones}
        />
      )}
      {isOpenRangesModal && (
        <ShippingCustomRangeModal
          ranges={ranges}
          range={selectedRange}
          isEditing={!!selectedRange}
          onChange={onChangeRanges}
          onClose={() => {
            closeRangesModal();
            setSelectedRange(undefined);
          }}
        />
      )}
    </Card>
  );
}

CustomShippingRangeOptionsCard.Skeleton = Skeleton;

export default CustomShippingRangeOptionsCard;

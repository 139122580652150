import { Fragment, useEffect } from 'react';
import { Box, Text } from '@nimbus-ds/components';
import { ErrorState } from '@tiendanube/components';
import { useManualPaidCharges } from 'domains/Billing/Checkout/pages/PlansAndSubscriptions/hooks';
import { trackingBillingCheckoutLastPayments } from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import { HistoricCharge } from './components';
import { LastPaymentsSkeleton } from './Skeleton';

export function LastPayments() {
  const t = useTranslationBilling(
    'checkout.plansAndSubscriptions.paymentsTabs.lastPayments',
  );
  const { manualPaidCharges, refreshMaualPaidCharges, isLoading, isError } =
    useManualPaidCharges();

  useEffect(() => {
    trackingBillingCheckoutLastPayments();
  }, []);

  if (isLoading) return <LastPaymentsSkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{
          children: t('error.retry'),
          onClick: () => {
            refreshMaualPaidCharges();
          },
        }}
      />
    );

  return (
    <Box display="flex" flexDirection="column">
      {!manualPaidCharges?.length && (
        <Box padding="4">
          <Text fontSize="highlight">{t('noPaymentsTitle')}</Text>
        </Box>
      )}
      {manualPaidCharges?.map((charge) => (
        <Fragment key={charge.id}>
          <HistoricCharge charge={charge} />
          <hr />
        </Fragment>
      ))}
    </Box>
  );
}

import { Box, Checkbox, CheckboxProps, Label } from '@nimbus-ds/components';
import { GetDocumentSettingsResponseDto } from '@tiendanube/common';
import { Stack } from 'commons/components';
import useTranslationFulfillmentOrders from 'domains/FulfillmentOrders/useTranslationFulfillmentOrders';
import LABEL_SETTINGS_OPTIONS, {
  LABEL_SETTING_RECEIPT_STUP,
  LABEL_SETTING_SUB_OPTIONS,
  SHOW_PRODUCTS_INFO_LABEL,
} from '../../LabelSettings';

interface PrintDocumentConfigurationProps {
  settings: GetDocumentSettingsResponseDto;
  isLoading: boolean;
  onChangeSetting: (name: string, value: boolean) => void;
  hasPickup: boolean;
}

function DocumentSettingsForm({
  settings,
  isLoading,
  onChangeSetting,
  hasPickup,
}: Readonly<PrintDocumentConfigurationProps>) {
  const t = useTranslationFulfillmentOrders('printManager.informationCard');

  const currentSettings: CheckboxProps[] = LABEL_SETTINGS_OPTIONS.map(
    ({ label, name }) => ({
      label,
      name,
      checked: settings[name],
    }),
  );

  const subCurrentSettings: CheckboxProps[] = LABEL_SETTING_SUB_OPTIONS.map(
    ({ label, name }) => ({
      label,
      name,
      checked: settings[name] && settings[SHOW_PRODUCTS_INFO_LABEL],
      disabled: !settings[SHOW_PRODUCTS_INFO_LABEL],
    }),
  );

  const allSubChecked = subCurrentSettings.every(({ checked }) => checked);
  const allSubUnchecked = subCurrentSettings.every(({ checked }) => !checked);

  const subCheckBoxList = Object.keys(subCurrentSettings).map((key) => {
    const { name, checked, label } = subCurrentSettings[key];

    return (
      <Box key={'subbox-' + key} marginLeft="5">
        <Checkbox
          key={key}
          name={name}
          checked={checked}
          disabled={!settings[SHOW_PRODUCTS_INFO_LABEL]}
          label={label ? t(label) : ''}
          onChange={(event) => onChangeSetting(name, event.target.checked)}
        />
      </Box>
    );
  });

  const baseCheckBoxList = Object.keys(currentSettings).map((key) => {
    const { name, checked, label } = currentSettings[key];
    const isIndeterminate = !allSubUnchecked && !allSubChecked && checked;

    return (
      <Box key={'box-' + key}>
        {label === SHOW_PRODUCTS_INFO_LABEL && (
          <Stack justify="flex-start" spacing="base" align="stretch" column>
            <Checkbox
              key={key}
              name={name}
              checked={checked && (allSubChecked || allSubUnchecked)}
              indeterminate={isIndeterminate}
              label={label ? t(label) : ''}
              onChange={(event) => {
                if (event.target.checked && isIndeterminate) {
                  onChangeSetting(name, !event.target.checked);
                  return;
                }
                onChangeSetting(name, event.target.checked);
              }}
            />
            {subCheckBoxList}
          </Stack>
        )}

        {label !== SHOW_PRODUCTS_INFO_LABEL && (
          <Checkbox
            key={key}
            name={name}
            checked={checked}
            label={label ? t(label) : ''}
            onChange={(event) => onChangeSetting(name, event.target.checked)}
          />
        )}
      </Box>
    );
  });

  return (
    <Stack justify="flex-start" align="stretch" column>
      <Label>{t('title')}</Label>
      {!isLoading && baseCheckBoxList}
      {!isLoading && hasPickup && (
        <Stack justify="space-between">
          <Checkbox
            key={LABEL_SETTING_RECEIPT_STUP.name}
            name={LABEL_SETTING_RECEIPT_STUP.name}
            checked={settings[LABEL_SETTING_RECEIPT_STUP.name]}
            label={t(LABEL_SETTING_RECEIPT_STUP.label ?? '')}
            onChange={(event) =>
              onChangeSetting(
                LABEL_SETTING_RECEIPT_STUP.name,
                event.target.checked,
              )
            }
          />
        </Stack>
      )}
      {isLoading && (
        <>
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
          <Checkbox.Skeleton />
        </>
      )}
    </Stack>
  );
}

export default DocumentSettingsForm;

import { Icon, Link } from '@nimbus-ds/components';
import { ExclamationCircleIcon } from '@nimbus-ds/icons';
import { EmptyMessage } from '@nimbus-ds/patterns';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, DataList, InterfaceLabel } from '@tiendanube/components';

interface CustomerPriceTablesErrorStateProps {
  headerLabel: InterfaceLabel;
  onRetry: () => void;
}

export default function CustomerPriceTablesErrorState({
  headerLabel,
  onRetry,
}: Readonly<CustomerPriceTablesErrorStateProps>) {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const tPath = 'customerDetails.customerPriceTables';

  return (
    <Card title={t(`${tPath}.title`)} headerLabel={headerLabel}>
      <DataList>
        <DataList.Row id="error-state">
          <DataList.Cell width={100} rowTitle>
            <EmptyMessage
              icon={
                <Icon
                  color="neutral-textHigh"
                  source={<ExclamationCircleIcon size={32} />}
                />
              }
              title=""
              text={t(`${tPath}.error.title`)}
              actions={
                <Link
                  appearance="primary"
                  textDecoration="none"
                  fontSize="highlight"
                  onClick={onRetry}
                >
                  {t(`${tPath}.error.retry`)}
                </Link>
              }
            />
          </DataList.Cell>
        </DataList.Row>
      </DataList>
    </Card>
  );
}

import { FEATURE_AI_TIER } from 'App/features';
import { useStatusFeatureByKey } from 'domains/Auth/hooks';

function useSupportsSuggestedGoogleShoppingCategories() {
  const { maxUse } = useStatusFeatureByKey(FEATURE_AI_TIER);

  return !['disabled'].includes(maxUse);
}

export default useSupportsSuggestedGoogleShoppingCategories;

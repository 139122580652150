import { useState } from 'react';
import {
  closestCenter,
  DndContext,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  useSortable,
  SortableContext,
  rectSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';
import { Icon, IconButton, Tooltip } from '@nimbus-ds/components';
import { EditIcon, PlayIcon, TrashIcon } from '@nimbus-ds/icons';
import { useIsMobileDevice } from 'domains/Auth/hooks';
import { useTranslationCatalog } from 'domains/Catalog/hooks';
import { ImageGalleryState } from '../../../ImageGallery';
import { Item } from '../Item';

import './Sortable.scss';

const mouseActivationConstraint = {
  delay: 0,
  tolerance: 5,
};
const touchActivationConstraint = {
  delay: 500,
  tolerance: 5,
};
export interface Props {
  items: ImageGalleryState[];
  isSelectMode: boolean;
  imagesSelected: string[];
  onSelected: (idSelected: string) => void;
  onRemove: (imageId: string) => void;
  onEdit: (imageId: string) => void;
  onOrder: (images: ImageGalleryState[]) => void;
  onError: () => void;
  ariaLabelError: string;
  newFileElement: JSX.Element;
  onClickImage: (i: ImageGalleryState) => void;
  onClickVideo: () => void;
}

export default function Sortable({
  items,
  isSelectMode,
  imagesSelected,
  onSelected,
  onRemove,
  onEdit,
  onError,
  onOrder,
  ariaLabelError,
  newFileElement,
  onClickImage,
  onClickVideo,
}: Props): JSX.Element {
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const isMobileDevice = useIsMobileDevice();
  const activeIndex = activeId
    ? items.findIndex((item) => item.id === activeId)
    : -1;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: mouseActivationConstraint,
    }),
    useSensor(TouchSensor, {
      activationConstraint: touchActivationConstraint,
    }),
  );

  const isAnyImageLoading = items.some((image) => image.isLoading);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={({ active }) => {
        if (!active) {
          return;
        }
        setActiveId(active.id);
      }}
      onDragEnd={({ over }) => {
        setActiveId(null);
        if (over) {
          const overIndex = items.findIndex((item) => item.id === over.id);
          if (activeIndex !== overIndex) {
            const arrayMoved = arrayMove(items, activeIndex, overIndex);
            onOrder(arrayMoved);
          } else {
            if (!isMobileDevice) onClickImage(items[activeIndex]);
          }
        }
      }}
      onDragCancel={() => {
        setActiveId(null);
      }}
      modifiers={[restrictToWindowEdges]}
    >
      <SortableContext items={items} strategy={rectSortingStrategy}>
        <ul className="stratus--image-gallery-modal__grid">
          {items.map((item, index) => (
            <SortableItem
              key={item.id}
              id={item.id}
              index={index}
              item={item}
              isSelectMode={isSelectMode}
              isSelected={imagesSelected.includes(item.id)}
              allowDragAndDrop={!isSelectMode && !isAnyImageLoading}
              onSelected={onSelected}
              onRemove={onRemove}
              onEdit={onEdit}
              onError={onError}
              onClickVideo={onClickVideo}
              ariaLabelError={ariaLabelError}
              onClickImage={isMobileDevice ? onClickImage : undefined}
            />
          ))}
          {newFileElement}
        </ul>
      </SortableContext>
    </DndContext>
  );
}

interface SortableItemProps {
  id: string;
  index: number;
  item: ImageGalleryState;
  isSelectMode: boolean;
  isSelected: boolean;
  allowDragAndDrop: boolean;
  onSelected: (idSelected: string) => void;
  onRemove: (imageId: string) => void;
  onEdit: (imageId: string) => void;
  onError: () => void;
  ariaLabelError: string;
  onClickImage?: (i: ImageGalleryState) => void;
  onClickVideo: () => void;
}

export function SortableItem({
  id,
  index,
  item,
  isSelectMode,
  isSelected,
  allowDragAndDrop,
  onSelected,
  onRemove,
  onError,
  onEdit,
  ariaLabelError,
  onClickImage,
  onClickVideo,
}: SortableItemProps): JSX.Element {
  const t = useTranslationCatalog();
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const handleOnRemove = () => onRemove(item.id);
  const handleOnEdit = () => onEdit(item.id);

  const { isError, isLoading } = item;
  const canShowButtons = !isSelectMode && !isLoading && !isSorting;
  const showDelete = canShowButtons;
  const showVideo =
    canShowButtons && item.mediaType === 'video' && !!item.publicUrl;
  const showEdit = canShowButtons && !isError && item.mediaType === 'image';

  return (
    <div key={item.id} className="stratus--image-gallery-modal__sortable-item">
      <Item
        ref={setNodeRef}
        dragging={isDragging}
        sorting={isSorting}
        index={index}
        transform={transform}
        isSelected={isSelected}
        transition={isDragging ? 'none' : transition}
        listeners={listeners}
        data-index={index}
        data-id={id}
        image={item}
        isSelectMode={isSelectMode}
        allowDragAndDrop={allowDragAndDrop}
        onSelected={onSelected}
        onError={onError}
        ariaLabelError={ariaLabelError}
        onClickImage={onClickImage}
        {...attributes}
      />
      {showVideo && (
        <div className="stratus--image-gallery-modal__sortable-item-video">
          <Icon
            color="neutral-background"
            onClick={onClickVideo}
            source={<PlayIcon size={40} />}
          />
        </div>
      )}
      <div className="stratus--image-gallery-modal__sortable-item-actions">
        {showEdit && (
          <Tooltip content={t('products.detail.editPhoto')}>
            <IconButton
              size="2rem"
              source={<EditIcon />}
              onClick={handleOnEdit}
            />
          </Tooltip>
        )}
        {showDelete && (
          <Tooltip
            content={
              item.mediaType === 'image'
                ? t('products.detail.deletePhoto')
                : t('products.detail.deleteVideo')
            }
          >
            <IconButton
              size="2rem"
              source={<TrashIcon />}
              onClick={handleOnRemove}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

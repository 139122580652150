/* eslint-disable max-statements */
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import { Domain } from '@tiendanube/common/src/enums';
import { useToastProgress } from 'App/components/ToastProgressContext';
import {
  CATALOG_HIDING_VARIANTS,
  CATALOG_UPLOAD_VIDEOS,
} from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { useToast, useDocumentTitle } from 'commons/hooks';
import { useGetLanguage, useHasShippingMultiCD } from 'domains/Auth/hooks';
import { CATALOG_ROUTES } from 'domains/Catalog/catalogRoutes';
import { useTrackFullCatalog } from 'domains/Catalog/hooks';
import AlertCreateProductsAndVariantsMetafields from 'domains/Metafields/components/Alerts/AlertCreateProductsAndVariantsMetafields';
import { productDefault } from './constants';
import { createProductDigest } from './productFormUtils';
import {
  trackingProductDetailSaveSuccess,
  trackingProductDetailSaveError,
} from '../../tracking';
import { FormProduct, ProductPage } from '../components';
import {
  useCreateProduct,
  useProductForm,
  useFetchProductVariantsMetafields,
} from '../hooks';
import { ProductFormState } from '../hooks/useProductForm/types';

function NewProductPage(): JSX.Element {
  const { selectFirstOptionMetafields } = useFetchProductVariantsMetafields();

  const hasShippingMultiCD = useHasShippingMultiCD();
  const history = useHistory();
  const language = useGetLanguage();
  const [hasUploadVideos, hasHidingVariants] = useHasTags([
    CATALOG_UPLOAD_VIDEOS,
    CATALOG_HIDING_VARIANTS,
  ]);

  const { t } = useTranslation(Domain.CATALOG);

  const { addProduct } = useCreateProduct();
  const { addToast } = useToast();
  const { addToastProgress, closeToastProgress } = useToastProgress();

  const sender = useTrackFullCatalog();
  const title = t('products.newProduct');
  useDocumentTitle(title);

  const onCreateProduct = async (data: Partial<ProductFormState>) => {
    addToastProgress({
      label: t('products.addingProduct'),
    });

    const params = createProductDigest({
      product: data,
      language,
      hasShippingMultiCD,
      hasMedia: hasUploadVideos,
      onlyVisibleVariants: !hasHidingVariants,
    });
    const newProduct = await addProduct(params);
    history.replace(
      generatePath(CATALOG_ROUTES.editProduct, { id: newProduct.id }),
    );
  };

  const onSuccess = () => {
    sender(trackingProductDetailSaveSuccess);

    closeToastProgress();
    addToast({
      label: t('products.toast.created'),
      appearance: 'success',
    });
  };

  const onError = () => {
    sender(trackingProductDetailSaveError);
    closeToastProgress();
    addToast({
      label: t('products.toast.createdError'),
      appearance: 'danger',
    });
  };

  const {
    values,
    errors,
    isDirty,
    isSaving,
    isFormSubmitted,
    showModalNullStockVariant,
    handleCloseModalNullStockVariant,
    handleConfirmModalNullVaritantStock,
    handleChange,
    handleChangeCategories,
    handleChangeImages,
    handleChangeVariants,
    handleChangeAttributes,
    handleSave,
    handleChangeToAllVariants,
    handleChangeSectionCodes,
    handleChangeToAllMetafieldsVariants,
    handleChangeRelatedProducts,
  } = useProductForm({
    initialValues: productDefault,
    selectFirstOptionMetafields,
    onSave: onCreateProduct,
    onError,
    onSuccess,
  });

  return (
    <ProductPage
      isSaving={isSaving}
      handleSave={handleSave}
      title={title}
      isDirty={isDirty}
      showModalNullStockVariant={showModalNullStockVariant}
      handleCloseModalNullStockVariant={handleCloseModalNullStockVariant}
      handleConfirmModalNullVaritantStock={handleConfirmModalNullVaritantStock}
      isEdit={false}
    >
      <AlertCreateProductsAndVariantsMetafields isEdit={false} />
      <FormProduct
        errors={errors}
        values={values}
        isFormSubmitted={isFormSubmitted}
        handleChange={handleChange}
        handleChangeCategories={handleChangeCategories}
        handleChangeImages={handleChangeImages}
        handleChangeVariants={handleChangeVariants}
        handleChangeAttributes={handleChangeAttributes}
        handleChangeToAllVariants={handleChangeToAllVariants}
        handleChangeSectionCodes={handleChangeSectionCodes}
        handleChangeToAllMetafieldsVariants={
          handleChangeToAllMetafieldsVariants
        }
        handleChangeRelatedProducts={handleChangeRelatedProducts}
      />
    </ProductPage>
  );
}

export default NewProductPage;

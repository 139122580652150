import { Box, Icon, Text } from '@nimbus-ds/components';
import { PictureIcon, PlayIcon } from '@nimbus-ds/icons';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function RecommendationsGridRow(): JSX.Element {
  const t = useTranslationCatalog('products.detail');
  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);

  return (
    <Box display="flex" flexDirection="column" gap="0-5">
      <Box display="flex" flexDirection="row" gap="2" alignItems="center">
        <Icon color="neutral-textLow" source={<PictureIcon size={14} />} />
        <Text fontSize="caption" color="neutral-textLow">
          {t('minSize')} / {t('recommendedFormats')}
        </Text>
      </Box>
      {hasUploadVideos && (
        <Box display="flex" flexDirection="row" gap="2" alignItems="center">
          <Icon color="neutral-textLow" source={<PlayIcon size={14} />} />
          <Text fontSize="caption" color="neutral-textLow">
            {t('videoSizeAndFormats')}
          </Text>
        </Box>
      )}
    </Box>
  );
}

export default RecommendationsGridRow;

import { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './VideoPlayer.scss';

type VideoPlayerProps = {
  videoUrl: string;
  onClose: () => void;
};

function VideoPlayer({ videoUrl, onClose }: VideoPlayerProps) {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const modalContent = (
    <div className="stratus--video-player">
      <div className="video-wrapper">
        <button className="close-button" onClick={onClose}>
          ✖
        </button>
        <iframe title="Video Player" src={videoUrl} allowFullScreen />
      </div>
    </div>
  );

  return ReactDOM.createPortal(modalContent, document.body);
}

export default VideoPlayer;

import { useMemo } from 'react';
import { useResponsive } from 'commons/components/Responsive';
import { useGetIsFreePlan } from 'domains/Auth/hooks';

export function useIsSavedSearchesEnabled() {
  const { isMobile } = useResponsive();
  const { isFreePlan } = useGetIsFreePlan();

  const isEnabled = useMemo(
    () => !isMobile && !isFreePlan,
    [isMobile, isFreePlan],
  );

  return isEnabled;
}

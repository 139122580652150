import React, { Fragment } from 'react';
import { Box, Card, Text, Title } from '@nimbus-ds/components';
import { ChevronRightIcon } from '@nimbus-ds/icons';
import { CheckoutPaymentMethod } from '@tiendanube/common';
import { ErrorState } from '@tiendanube/components';
import { useToastStatus } from 'commons/hooks';
import { combineStatus } from 'commons/utils/combineStatus';
import {
  usePaymentMethods,
  usePayorderToPay,
} from 'domains/Billing/Checkout/hooks';
import useTranslatePaymentMethod from 'domains/Billing/Checkout/hooks/useTranslatePaymentMethod';
import {
  getIconForPaymentMethod,
  paymentMethodIsMercadoPago,
} from 'domains/Billing/Checkout/pages/utils';
import { trackingBillingSelectPaymentMethodForPlanClick } from 'domains/Billing/Checkout/tracking';
import useTranslationBilling from 'domains/Billing/useTranslationBilling';
import ChoosePaymentMethodSkeleton from './Skeleton';
import { useContinuePayment } from '../../../ChoosePaymentRecurrencyPage/hooks';

export default function ChoosePaymentMethod() {
  const t = useTranslationBilling('checkout.choosePaymentMethodWithRecurrency');
  const {
    payOrderId,
    recurrentPaymentCompatible,
    status: payorderStatus,
    payorderToPay,
    isForPlan,
    feePercentageForMethod,
  } = usePayorderToPay();
  const {
    refreshPaymentMethods,
    paymentMethods: paymentMethodsForPlan,
    status: paymentMethodsStatus,
  } = usePaymentMethods(isForPlan);
  const { isLoading, isError } = combineStatus(
    payorderStatus,
    isForPlan ? paymentMethodsStatus : undefined,
  );
  const paymentMethods = payorderToPay?.allowedPaymentMethods;
  const { mainConcept } = usePayorderToPay();
  const {
    onPay,
    status: continueStatus,
    goToRecurrencies,
  } = useContinuePayment();
  const { translateCheckoutPaymentMethod } = useTranslatePaymentMethod();

  useToastStatus({
    status: continueStatus,
    error: t('chooseMethod.continueError'),
    progress: t('chooseMethod.continueLoading'),
  });

  if (isLoading) return <ChoosePaymentMethodSkeleton />;
  if (isError)
    return (
      <ErrorState
        title={t('error.title')}
        action={{
          children: t('error.retry'),
          onClick: refreshPaymentMethods,
        }}
      />
    );

  const handleSelectMethod = (selectedMethod: CheckoutPaymentMethod) => {
    const logClick = (recurrenciesAvailable: boolean) =>
      trackingBillingSelectPaymentMethodForPlanClick(
        selectedMethod,
        mainConcept,
        recurrenciesAvailable,
      );

    if (isForPlan) {
      const recurrencies = paymentMethodsForPlan?.find(
        ({ method }) =>
          method === selectedMethod ||
          (paymentMethodIsMercadoPago(selectedMethod) && method === 'ticket'),
      )?.recurrencyOptions;
      if (recurrencies?.length === 1) {
        logClick(false);
        onPay(selectedMethod, recurrencies[0]);
      } else {
        logClick(true);
        goToRecurrencies(payOrderId, selectedMethod);
      }
    } else {
      logClick(false);
      onPay(selectedMethod);
    }
  };

  return (
    <Card padding="none">
      <Box display="flex" flexDirection="column">
        <Box padding="4">
          <Title as="h4">{t('chooseMethod.title')}</Title>
        </Box>
        {paymentMethods?.map((method) => {
          const icon = getIconForPaymentMethod(method);
          const feePercentage = feePercentageForMethod(method);
          return (
            <Fragment key={method}>
              <hr />
              <Box
                onClick={() => handleSelectMethod(method)}
                padding="4"
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  gap="3"
                  alignItems="center"
                >
                  {icon && React.createElement(icon)}
                  <Box display="flex" flexDirection="column" gap="2">
                    <Text>
                      {translateCheckoutPaymentMethod(
                        method,
                        recurrentPaymentCompatible,
                      )}
                    </Text>
                    {!!feePercentage && (
                      <Text fontSize="caption" color="neutral-textLow">
                        {t('extraFee', { percentage: feePercentage })}
                      </Text>
                    )}
                  </Box>
                </Box>
                <ChevronRightIcon />
              </Box>
            </Fragment>
          );
        })}
      </Box>
    </Card>
  );
}

import {
  CheckoutPaymentMethod,
  ConceptCode,
  SubscriptionFrequencyType,
} from '@tiendanube/common';
import { logEvent } from 'commons/utils/tracking';
import {
  BILLING_CHANGE_PLANS_CLICK,
  BILLING_CHECKOUT_CREDIT_CARD_PAY_CLICK,
  BILLING_CHECKOUT_SELECT_METHOD_FOR_PLAN_CLICK,
  BILLING_CHECKOUT_SELECT_RECURRENCY_CLICK,
  BILLING_CHECKOUT_START_PAYMENT,
  BILLING_CHECKOUT_TRANSACTION_FEES_RP_CHECKBOX,
  BILLING_CHECKOUT_VIEW_DETAILS,
  BILLING_CHECKOUT_WIRE_TRANSFER_PAY_CLICK,
  BILLING_LAST_PAYMENTS,
  BILLING_LAST_PAYMENTS_TAB_CLICK,
  BILLING_PAYORDER_CHECKOUT_CONTINUE_CLICK,
  BILLING_PAYORDER_CHECKOUT_FINISH,
  BILLING_PAYORDER_CHECKOUT_VIEW,
  BILLING_RECURRING_CANCEL_CLICK,
  BILLING_VIEW_CHECKOUT_CHARGES,
  BILLING_VIEW_CHECKOUT_CHARGES_AFTER_PAYMENT,
  BILLING_VIEW_CHECKOUT_PAY_CREDIT_CARD,
  BILLING_VIEW_CHECKOUT_PAY_WIRE_TRANSFER,
  BILLING_VIEW_CHECKOUT_SELECT_METHOD_FOR_PLAN,
} from 'config/trackingEvents';

export type VIEW_DETAILS_ORIGIN =
  | 'lastPayments'
  | 'chargesToPay'
  | 'recurrentPaymentStatus';

export const trackingBillingViewCheckoutCharges = (): void => {
  logEvent(BILLING_VIEW_CHECKOUT_CHARGES, {});
};

export const trackingBillingViewCheckoutChargesAfterPayment = (): void => {
  logEvent(BILLING_VIEW_CHECKOUT_CHARGES_AFTER_PAYMENT, {});
};

export const trackingBillingStartPayment = (
  isCreatingChargeForChurned: boolean,
  mainConcept?: ConceptCode,
): void => {
  mainConcept &&
    logEvent(BILLING_CHECKOUT_START_PAYMENT, {
      main_concept: mainConcept,
      is_creating_charge_for_churned: isCreatingChargeForChurned.toString(),
    });
};

export const trackingBillingViewSelectPaymentMethodForPlan = (
  mainConcept?: ConceptCode,
): void => {
  mainConcept &&
    logEvent(BILLING_VIEW_CHECKOUT_SELECT_METHOD_FOR_PLAN, {
      main_concept: mainConcept,
    });
};

export const trackingBillingSelectPaymentMethodForPlanClick = (
  method: CheckoutPaymentMethod,
  mainConcept?: ConceptCode,
  recurrenciesAvailable = false,
): void => {
  mainConcept &&
    logEvent(BILLING_CHECKOUT_SELECT_METHOD_FOR_PLAN_CLICK, {
      payment_method: method,
      main_concept: mainConcept,
      recurrencies_available: recurrenciesAvailable.toString(),
    });
};

export const trackingBillingSelectRecurrencyClick = (
  recurringInterval: number,
  recurringFrequency: SubscriptionFrequencyType,
  mainConcept?: ConceptCode,
): void => {
  mainConcept &&
    logEvent(BILLING_CHECKOUT_SELECT_RECURRENCY_CLICK, {
      recurring_interval: recurringInterval.toString(),
      recurring_frequency: recurringFrequency,
      main_concept: mainConcept,
    });
};

export const trackingBillingViewPayCreditCard = (
  mainConcept?: ConceptCode,
): void => {
  mainConcept &&
    logEvent(BILLING_VIEW_CHECKOUT_PAY_CREDIT_CARD, {
      main_concept: mainConcept,
    });
};

export const trackingBillingPayCreditCardClick = (
  mainConcept?: ConceptCode,
): void => {
  mainConcept &&
    logEvent(BILLING_CHECKOUT_CREDIT_CARD_PAY_CLICK, {
      main_concept: mainConcept,
    });
};

export const trackingBillingViewPayWireTransfer = (
  mainConcept?: ConceptCode,
): void => {
  mainConcept &&
    logEvent(BILLING_VIEW_CHECKOUT_PAY_WIRE_TRANSFER, {
      main_concept: mainConcept,
    });
};

export const trackingBillingPayWireTransferClick = (
  mainConcept?: ConceptCode,
): void => {
  mainConcept &&
    logEvent(BILLING_CHECKOUT_WIRE_TRANSFER_PAY_CLICK, {
      main_concept: mainConcept,
    });
};

export const trackingBillingRecurringCancel = (): void => {
  logEvent(BILLING_RECURRING_CANCEL_CLICK, {});
};

export const trackingBillingChangePlansClick = (): void => {
  logEvent(BILLING_CHANGE_PLANS_CLICK, {});
};

export const trackingBillingCheckoutLastPayments = (): void => {
  logEvent(BILLING_LAST_PAYMENTS, {});
};

export const trackingBillingCheckoutViewDetails = (
  origin: VIEW_DETAILS_ORIGIN,
): void => {
  logEvent(BILLING_CHECKOUT_VIEW_DETAILS, { origin });
};

export const trackingBillingPayOrderCheckoutView = (
  mainConcept: ConceptCode,
): void => {
  logEvent(BILLING_PAYORDER_CHECKOUT_VIEW, {
    main_concept: mainConcept,
  });
};

export const trackingBillingLastPaymentsTabClick = (): void => {
  logEvent(BILLING_LAST_PAYMENTS_TAB_CLICK, {});
};

export const trackingBillingPayOrderCheckoutContinueClick = (
  method: CheckoutPaymentMethod,
  mainConcept?: ConceptCode,
): void => {
  mainConcept &&
    logEvent(BILLING_PAYORDER_CHECKOUT_CONTINUE_CLICK, {
      payment_method: method,
      main_concept: mainConcept,
    });
};

export const trackingBillingCheckoutTransactionFeesRpCheckbox = (
  checked: boolean,
): void => {
  logEvent(BILLING_CHECKOUT_TRANSACTION_FEES_RP_CHECKBOX, {
    checked: checked.toString(),
  });
};

export const trackingBillingPayOrderCheckoutFinish = (
  mainConcept: ConceptCode,
): void => {
  logEvent(BILLING_PAYORDER_CHECKOUT_FINISH, {
    main_concept: mainConcept,
  });
};

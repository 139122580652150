import { Box, Skeleton, Text } from '@nimbus-ds/components';
import { useHistory } from 'react-router-dom';
import { Card, Input, Stack } from '@tiendanube/components';
import { CATALOG_UPLOAD_VIDEOS } from 'App/featuresFlags';
import { useHasTags } from 'App/hooks';
import { CardWithTitle, Repeater, useResponsive } from 'commons/components';
import {
  HeaderContent,
  HeaderTop,
  IonPageStratus,
} from 'commons/components/IonPageStratus';
import useTranslationCatalog from 'domains/Catalog/useTranslationCatalog';

function ImageItem() {
  return <Skeleton width="100%" height="88px" borderRadius="0.5rem" />;
}

function ProductDetailsSkeleton(): JSX.Element {
  const t = useTranslationCatalog();
  const [hasUploadVideos] = useHasTags([CATALOG_UPLOAD_VIDEOS]);
  const { isDesktop } = useResponsive();
  const history = useHistory();

  const handleGoBack = () => history.goBack();

  const backNavigation = {
    onClick: handleGoBack,
  };

  const title = hasUploadVideos
    ? t('products.detail.media')
    : t('products.detail.photos');

  const maxImages = isDesktop ? 4 : 3;

  return (
    <IonPageStratus
      headerTop={<HeaderTop navigation={backNavigation} />}
      headerContent={<HeaderContent title="skeleton" />}
      width="small"
    >
      <Stack column spacing="loose" align="stretch">
        <Card title={t('products.detail.nameAndDescription')}>
          <Input.Skeleton />
          <Input.Skeleton rows={10} />
        </Card>
        <CardWithTitle type="h4" title={title}>
          <Box display="flex" flexDirection="column" gap="4">
            <Skeleton width="100%" height="88px" borderRadius="0.5rem" />
            <Box
              display="grid"
              gridTemplateColumns={`repeat(${maxImages}, 1fr)`}
              gridGap="4"
            >
              <Repeater times={maxImages} item={ImageItem} />
            </Box>
            <Box display="flex" flexDirection="column" gap="1">
              <Text.Skeleton width="70%" />
              <Text.Skeleton width="70%" />
            </Box>
          </Box>
        </CardWithTitle>
        <Card title={t('products.detail.typeOfProduct')}>
          <Stack column align="flex-start" spacing="tight">
            <Text.Skeleton width="70%" />
            <Text.Skeleton width="70%" />
          </Stack>
        </Card>
      </Stack>
    </IonPageStratus>
  );
}

export default ProductDetailsSkeleton;

export const BRAZIL = {
  THEMES: {
    id: 12173,
    name: 'iShorts - Video Commerce',
    description:
      'Video Commerce na sua loja em todos os momentos. TESTE GRÁTIS!',
    image: '12173-pt_BR-logo-ishorts.png',
    urlText: 'Mais apps de marketing',
    categoryUri: 'marketing',
    handle: 'ishorts',
  },
  ABANDONED_CARTS: {
    id: 1333,
    name: 'Sak - Atendimento via WhatsApp',
    description:
      'Converta carrinhos abandonados, PIX e boletos bancários via WhatsApp. Envie mensagens e e-mails auto...',
    image: '1333-pt_BR-small-sak-nova-animacao-gif.gif',
    urlText: 'Mais apps de comunicação',
    categoryUri: 'comunicacao',
    handle: 'sak-recuperador-de-carrinhos-whatsapp',
  },
  MESSAGES: {
    id: 5526,
    name: 'Chaty: botões do WhatsApp e Chat',
    description:
      'Mostre mais de 20 aplicativos de bate-papo e widgets sociais, incluindo WhatsApp, Facebook Messenger...',
    image: '5526-chaty-icon-1.gif',
    urlText: 'Mais apps de comunicação',
    categoryUri: 'comunicacao',
    handle: 'chaty',
  },
  DISCOUNT: undefined,
  COUPONS: {
    id: 3538,
    name: 'HintUP Promoções',
    description:
      'Promoções com: Descontos especiais, progressivos, brindes, cupom de primeira compra, kits, utm, e mu...',
    image: '3538-pt_BR-marca_com_r.png',
    urlText: 'Mais apps de marketing',
    categoryUri: 'marketing',
    handle: 'hintup-promocoes',
  },
  FREE_SHIPPING: undefined,
  PROMOTIONS: {
    id: 3538,
    name: 'HintUP Promoções',
    description:
      'Promoções com: Descontos especiais, progressivos, brindes, cupom de primeira compra, kits, utm, e mu...',
    image: '3538-pt_BR-marca_com_r.png',
    urlText: 'Mais apps de marketing',
    categoryUri: 'marketing',
    handle: 'hintup-promocoes',
  },
  PRODUCTS: undefined,
  CUSTOMERS: {
    id: 9882,
    name: 'edrone',
    description:
      'Ferramenta de CRM e Automação de Marketing feita sob medida para lojas online. Tudo em um único sist...',
    image: '9882-pt_BR-600-logo-edrone.png',
    urlText: 'Mais apps de marketing',
    categoryUri: 'marketing',
    handle: 'edrone2',
  },
  ORDERS: {
    id: 2503,
    name: 'Super Checkout',
    description: 'A caixa de ferramentas completa para seu Checkout',
    image: '2503-pt_BR-Composi--o-2.gif',
    urlText: 'Mais apps de marketing',
    categoryUri: 'marketing',
    handle: 'checkoutt',
  },
  DRAFT_ORDERS: {
    id: 511,
    name: 'Omie',
    description:
      'Conheça o sistema de gestão Omie, a melhor solução de gestão online para o seu negócio.',
    image: '511-pt_BR-small-3945-pt_BR-small-logo 600x600-03 azul.jpg',
    urlText: 'Mais apps de gestão',
    categoryUri: 'gestao',
    handle: 'omie-erp',
  },
  EMAILS: undefined,
  INFO_MESSAGES: {
    id: 14714,
    name: 'Carrinho limitado by TITANPush',
    image: '14714-pt_BR-opciÃ³n 3 (1).png',
    handle: 'carrinho-limitado-by-titanpush',
    description:
      'Crie regras de compra personalizadas para seus clientes, controle o avanço do carrinho garantindo o cumprimento das suas políticas de venda.',
    urlText: 'Mais apps de marketing',
    categoryUri: 'marketing',
  },
};

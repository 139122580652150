import { useSelector } from 'react-redux';
import { PickupPointRequestDto } from '@tiendanube/common';
import { getLocationsStatus } from 'domains/Shipping/Locations/locationSlice/locationSelectors';
import { COUNTRIES_WITH_GEOLOCATION_INFO } from 'domains/Shipping/utils';
import PickupBusinessDays from './components/PickupBusinessHours';
import { sortBusinessHours } from './components/PickupBusinessHours/utils';
import PickupPointCost from './components/PickupPointCost';
import PickupReadyTime from './components/PickupReadyTime';
import Range from './components/Range';
import SecondStepFormSkeleton from './Skeleton';
import { useGetCountry } from '../../../../../../../../Auth/hooks';
import usePickupPoints from '../../hooks/usePickupPoints';

interface SecondStepFormProps {
  errors: Partial<Record<string, string>>;
  values: PickupPointRequestDto;
  setFieldValue: (name: string, value: any) => void;
}

function SecondStepForm({
  values,
  errors,
  setFieldValue,
}: Readonly<SecondStepFormProps>): JSX.Element {
  const { isLoadingDetail } = usePickupPoints();
  const { isLoading: isLoadingLocation } = useSelector(getLocationsStatus);
  const isLoading = isLoadingLocation || isLoadingDetail;
  const storeCountry = useGetCountry();

  return (
    <>
      {isLoading && <SecondStepForm.Skeleton />}
      {!isLoading && (
        <>
          <PickupBusinessDays
            businessHours={sortBusinessHours(values.businessHours)}
            errors={errors}
            setFieldValue={setFieldValue}
          />
          <PickupReadyTime
            time={values.estimateDeliveryTime}
            errors={errors}
            setFieldValue={setFieldValue}
          />
          <PickupPointCost
            cost={values.cost}
            errors={errors}
            setFieldValue={setFieldValue}
          />
          {COUNTRIES_WITH_GEOLOCATION_INFO.has(storeCountry) && (
            <Range
              range={values.range}
              errors={errors}
              setFieldValue={setFieldValue}
            />
          )}
        </>
      )}
    </>
  );
}

SecondStepForm.Skeleton = SecondStepFormSkeleton;
export default SecondStepForm;

import { Box, Link, Title, Text } from '@nimbus-ds/components';
import { useTranslation } from 'react-i18next';
import { Domain } from '@tiendanube/common/src/enums';
import { Card, DataList, InterfaceLabel } from '@tiendanube/components';

interface CustomerPriceTablesEmptyStateProps {
  headerLabel: InterfaceLabel;
  onClick: () => void;
}

export default function CustomerPriceTablesEmptyState({
  headerLabel,
  onClick,
}: Readonly<CustomerPriceTablesEmptyStateProps>) {
  const { t } = useTranslation(Domain.CUSTOMERS);
  const tPath = 'customerDetails.customerPriceTables';

  return (
    <Card title={t(`${tPath}.title`)} headerLabel={headerLabel}>
      <DataList>
        <DataList.Row id="error-state">
          <DataList.Cell width={100} rowTitle>
            <Box
              display="flex"
              flexDirection="column"
              gap="1"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Title as="h5" textAlign="center">
                {t(`${tPath}.emptyState.title`)}
              </Title>
              <Text textAlign="center">{t(`${tPath}.emptyState.text`)}</Text>
              <Box
                display="flex"
                justifyContent={{
                  xs: 'center',
                  lg: 'flex-start',
                }}
                gap="2"
                mt="1"
                flexWrap="wrap"
              >
                <Link
                  appearance="primary"
                  textDecoration="none"
                  fontSize="highlight"
                  onClick={onClick}
                >
                  {t(`${tPath}.emptyState.link`)}
                </Link>
              </Box>
            </Box>
          </DataList.Cell>
        </DataList.Row>
      </DataList>
    </Card>
  );
}

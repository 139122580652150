enum AiFeature {
  AI_PRODUCTS_SEO = "ai-products-seo",
  AI_PRODUCTS_DESCRIPTION = "ai-products-description",
  AI_PRODUCT_IMAGE_ALT_TEXT = "ai-product-image-alt-text",
  AI_CATEGORIES_SEO = "ai-categories-seo",
  AI_PAGES = "ai-pages",
  AI_IMAGES_REMOVE_BG = "ai-images-remove-bg",
  AI_IMAGES_REPLACE_BG_COLOR = "ai-images-replace-bg-color",
  AI_IMAGES_REPLACE_BG_IMAGE = "ai-images-replace-bg-image",
  AI_IMAGES_SMART_ERASE = "ai-images-smart-erase",
  AI_GOOGLE_SHOPPING_CATEGORIES = "ai-google-shopping-categories",
}

export default AiFeature;

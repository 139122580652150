import React, { useState, DragEvent } from 'react';
import classNames from 'classnames';
import { useTranslationCatalog } from 'domains/Catalog/hooks';

import './FileDraggable.scss';

interface FileDraggableProps {
  children: React.ReactNode;
  onDropFiles: (files: File[]) => void;
}

/**
 * @component FileDraggable
 * @description A component that provides drag-and-drop functionality for files.
 * It manages drag states to visually indicate when an item is being dragged over
 * the component. It supports custom drag-over behavior and triggers a callback when
 * files are dropped.
 */
function FileDraggable({
  children,
  onDropFiles,
}: Readonly<FileDraggableProps>): JSX.Element {
  const [dragActive, setDragActive] = useState<boolean>(false);

  const t = useTranslationCatalog();

  const preventAndStop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const active = (value: boolean) => {
    setDragActive(value);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    preventAndStop(e);
    dragActive && active(false);
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    preventAndStop(e);
    !dragActive && active(true);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    preventAndStop(e);
    active(false);
    if (e.dataTransfer.files?.[0]) {
      onDropFiles(Object.values(e.dataTransfer.files));
    }
  };

  const className = classNames('stratus--file-draggable', {
    'drag-active': dragActive,
  });

  return (
    <section
      aria-label={t('products.detail.fileDraggable')}
      className={className}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {children}
    </section>
  );
}

export default FileDraggable;
